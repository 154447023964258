import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/app-constants";
import { BaseResponse } from "../models/response/base-response";

export const quoteService = createApi({
  reducerPath: "quoteService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),
  tagTypes: ["Quote"],
  endpoints: (build) => ({
    createQuote: build.mutation<BaseResponse<any[]>, any>({
      query: (body) => ({
        url: "quotes",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Quote"],
    }),
    makePayments: build.mutation<BaseResponse<any[]>, any>({
      query: (body) => ({
        url: "/payments/manila/checkout",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Quote"],
    }),
  }),
});

export const { useCreateQuoteMutation, useMakePaymentsMutation } = quoteService;
