import React from "react";
import { useLocation } from "react-router-dom";
import BookMeeting from "./components/BookMeeting";

const InsuranceService = () => {
  const location = useLocation();
  const { slide } = location.state || {};
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${slide?.image || "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610407/purchase_z0nwih.webp"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-[#003862] h-[60vh] w-full flex items-center justify-center bg-opacity-80">
          <div className="px-4 sm:px-6 lg:px-8 py-10 space-y-8">
            <div className="max-w-5xl text-center mx-auto">
              <h1 className="block font-bold text-gray-200 text-4xl sm:text-5xl">
                {slide?.title || "Insurance Broking"}
              </h1>
              <p className="text-white text-lg py-2">Our Services</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-5xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
          <div>
            <img className="rounded-xl" src={slide?.image || "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610407/purchase_z0nwih.webp"} alt="" />
          </div>

          <div className="mt-5 sm:mt-10 lg:mt-0">
            <div className="space-y-6 sm:space-y-8">
              <div className="space-y-2 md:space-y-4">
                <p className="text-[#003862] font-medium">
                  {slide?.body ||
                    "We assist our clients, both individuals and businesses to find suitable insurance coverage by comparing options and analyzing their needs to obtain policies tailored to their specific requirements."}
                </p>
              </div>

              <BookMeeting modalImage={slide?.image} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceService;
