import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../presentation/components/Footer";
import NavHeader from "../presentation/components/NavHeader";

const LandingPageLayout = () => {
  return (
    <div>
      <NavHeader />
      <Outlet />
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
