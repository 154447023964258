import React, { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { useVerifyUserByMsisdnMutation } from "../../../../../../services/auth-service";
import { homeOwnerRequest } from "./HomeOwnerInsurancePlanData";
import HomeOwnerConfirmationModal from "./HomeOwnerInsuranceFormConfirmationModal";
import { useCreateQuoteMutation } from "../../../../../../services/quote-service";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowRight } from "../../../../../assets";
import { GoDotFill } from "react-icons/go";
import Tooltip from "../../../../../components/Tooltip";
import HomeOwnersTestModal from "./HomeOwnersTestModal";

const HomeOwnerInsuranceForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(sessionStorage.getItem("access_token"));
  const [isOpen, setIsOpen] = useState(false);

  const [policyResponse, setPolicyResponse] = useState<any>(null);
  const [userId, setUserId] = useState(sessionStorage.getItem("userId"));
  const [userMsisdn, setUserMsisdn] = useState(
    sessionStorage.getItem("userMsisdn")
  );

  const [selectedProductId, setSelectedProductId] = useState(
    sessionStorage.getItem("selectedInsuranceProductId")
  );

  const [formData, setFormData] = useState<any>({
    customerId: userId || "",
    customerMsisdn: userMsisdn || "",
    productId: selectedProductId || "",
    applicationEnum: "WEB_APP",
    insurancePlanEnum: homeOwnerRequest.insuranceTypeEnum,
    coverType: "",
    homeOwnersInsuranceRequestDto: {
      buildingValue: 0,
      itemValue: 0,
      location: "",
      natureOfStockAndGoods: "",
      proposalName: "",
    },
  });

  const handleFormChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      homeOwnersInsuranceRequestDto: {
        ...prevFormData.homeOwnersInsuranceRequestDto,
        [name]: value,
      },
    }));
  };

  const [createQuote] = useCreateQuoteMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createQuote(formData);
      console.log(response);
      const { code, message, data } = response.error
        ? response.error["data"]
        : response["data"];
      if (code === "00") {
        const policyData = data[0];
        setPolicyResponse(policyData);
        toast.success(message);
        setIsOpen(true);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  // Store the current location before navigating to login
  const handleLoginRedirect = () => {
    sessionStorage.setItem("previousLocation", location.pathname);
    navigate("/auth/login");
  };

  return (
    <div className="lg:col-span-2 my-auto">
      {!token && (
        <div className=" border-dotted border-b-2 mb-5">
          <p className="  text-sm font-medium  text-gray-900 ">
            Please{"  "}
            <span
              onClick={handleLoginRedirect}
              className="font-bold underline text-[#31A5F8] hover:text-sky-600 cursor-pointer"
            >
              Login
            </span>{" "}
            to proceed! Don't have an account yet?{" "}
            <Link
              to="/auth/sign-up"
              className="underline font-bold text-[#31A5F8] hover:text-sky-600"
            >
              Sign up
            </Link>{" "}
          </p>
        </div>
      )}
      <form onSubmit={handleFormSubmit}>
        <div className="grid gap-5 row-gap-5 sm:grid-cols-2 my-4">
          {homeOwnerRequest.homeownerfields.map((field, index) => (
            <div className="space-y-2 " key={index}>
              <label
                htmlFor={field.name}
                className="inline-block text-sm font-medium text-[#003862] mt-2.5"
              >
                {field.label}
              </label>
              <Tooltip content={"Please login to proceed"} showTooltip={!token}>
                {field.type === "text" && (
                  <input
                    id={field.name}
                    type="text"
                    name={field.name}
                    required={field.required}
                    disabled={!token}
                    onChange={handleFormChanged}
                    className="py-4 px-3 block w-full border-2 border-gray-200 bg-gray-50 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                )}
                {field.type === "date" && (
                  <input
                    id={field.name}
                    type="date"
                    name={field.name}
                    required={field.required}
                    disabled={!token}
                    onChange={handleFormChanged}
                    className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm rounded-lg bg-gray-50 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                )}
                {field.type === "number" && (
                  <input
                    id={field.name}
                    type="number"
                    name={field.name}
                    required={field.required}
                    disabled={!token}
                    onChange={handleFormChanged}
                    className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                )}
                {field.type === "select" && field.options && (
                  <select
                    id={field.name}
                    name={field.name}
                    required={field.required}
                    disabled={!token}
                    onChange={handleFormChanged}
                    className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <option selected disabled value="">
                      Select an option
                    </option>
                    {field.options.map((option, idx) => (
                      <option key={idx} value={option.key}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                )}
                {field.type === "file" && (
                  <input
                    id={field.name}
                    type="file"
                    name={field.name}
                    required={field.required}
                    disabled={!token}
                    className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                )}
              </Tooltip>
            </div>
          ))}
        </div>
        <div className="my-5 flex">
          <input
            type="checkbox"
            disabled={!token}
            required
            className="shrink-0 mt-0.5 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
            id="af-submit-application-privacy-check"
          />
          <label
            htmlFor="af-submit-application-privacy-check"
            className="text-sm text-gray-700 ms-2"
          >
            I/We, the undersigned, do hereby warrant the truth and correctness
            of all above statements and particulars and I/We declare that I/We
            have not withheld any material Information. I/We agree that the
            above proposal and this warranty and declaration shall be the basis
            of the Contract between me/us, and Banbo Insurance Brokers and I/We
            agree to abide by the terms and conditions of the Policy issued in
            answer to this Proposal. I authorize Banbo to renew my policy
            annually on my behalf.
            <br />
            <br />
            <span className="font-bold">Important Note:</span> No liability is
            undertaken until the underwriters accept the proposal and the
            Premium is paid.
          </label>
        </div>
        {/* <button
          type="submit"
          disabled={!token}
          className=" flex underline group justify-end w-full font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200"
        >
          Next
          <span
            aria-hidden="true"
            className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
          >
            <img src={ArrowRight} alt="" />
          </span>
        </button> */}
      </form>
      {/* <HomeOwnerConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        policyResponse={policyResponse}
        formData={formData}
      /> */}
      <HomeOwnersTestModal/>
      <div className="w-full border-t-2 mt-10">
        <p className="text-center flex gap-2 items-center pt-5">
          <GoDotFill className=" text-heading  text-red-600" />
          Please note that a Rep will visit your home for inspection
        </p>
      </div>
    </div>
  );
};

export default HomeOwnerInsuranceForm;
