import React, { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signInRequest } from "../../../../../../models/request/auth-request";
import { useSignInMutation } from "../../../../../../services/auth-service";
import toast from "react-hot-toast";
import Loader from "../../../../../components/Loader";
import { FiEye, FiEyeOff } from "react-icons/fi";

const LoginCard = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<signInRequest>({
    msisdn: "",
    password: "",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [userSignIn, { isLoading }] = useSignInMutation();

  const handleFormSubmit = async (e) => {
    // e.preventDefault();
    try {
      // Prepend 233 to the msisdn value
      const formattedFormData = {
        ...formData,
        msisdn: `233${formData.msisdn}`,
      };

      const response = await userSignIn(formattedFormData);
      console.log("loginResponse", response)
      const { code, message, data } = response.error ? response.error["data"] : response["data"]
      if (code === "00") {
        toast.success(message);
        const { access_token } = data;
        sessionStorage.setItem("access_token", access_token);
        const { id, name, email, msisdn, dateOfBirth } = data.user;

        sessionStorage.setItem("userId", id);
        sessionStorage.setItem("userFullName", name);
        sessionStorage.setItem("userMsisdn", msisdn);
        sessionStorage.setItem("userEmail", email);
        sessionStorage.setItem("userDOB", dateOfBirth);

        // Retrieve the previous location and navigate to it
        const previousLocation = sessionStorage.getItem("previousLocation");
        if (previousLocation) {
          navigate(previousLocation);
        } else {
          navigate("/dashboard");
        }
      } else if (
        message ===
        "Customer has not been activated. Please create a password to activated your account"
      ) {
        navigate("/auth/create-password", {
          state: { msisdn:formData.msisdn },
        });
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <div>
      <div className="h-[100vh] w-full flex justify-center items-center">
        <div className="block w-full mt-10 md:max-w-md p-8 bg-[#F8F9FA] rounded-3xl">
          {/* <a href="/">
            <img src={LogoDark} className="mx-auto w-28" alt="" />
          </a> */}
          <p className="text-3xl text-[#125182] text-center font-extrabold">
            Sign In
          </p>
          <div className="my-6">
            <div className="mb-3">
              <label htmlFor="phoneNumber" className=" text-sm text-gray-500  ">
                Phone Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex  items-center ps-2 pointer-events-none">
                  <p className="text-xs font-medium">+233</p>
                </div>
                <input
                  type="text"
                  className="block w-full border-0  p-4 ps-12 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                  placeholder="50xxxxxxx"
                  {...register("msisdn", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{9}$/,
                      message: "Phone number must be 9 digits",
                    },
                  })}
                  onChange={handleInputChange}
                />
                <p className="text-red-500 text-xs">
                  {errors?.msisdn?.message?.toString()}
                </p>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="phoneNumber" className=" text-sm text-gray-500  ">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleInputChange}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                />
                {showPassword ? (
                  <FiEyeOff
                    className="absolute end-2.5 bottom-[0.95rem] text-lg"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <FiEye
                    className="absolute end-2.5 bottom-[0.95rem] text-lg"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <button
              disabled={!formData.password || !formData.msisdn}
              onClick={handleSubmit(handleFormSubmit)}
              className="w-full px-4 py-3 tracking-wide rounded-lg text-white transition-colors duration-200 transform bg-[#125182] hover:bg-[#215CA4] focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              {isLoading ? (
                <p className="mx-auto text-center">
                  <Loader />
                </p>
              ) : (
                "Login"
              )}
            </button>
          </div>
          <p className=" text-sm text-center text-blue-500">
            <Link
              to="/auth/forget-password"
              className="text-blue-500 focus:outline-none focus:underline hover:underline"
            >
              Forget password?
            </Link>
          </p>
          <p className=" text-sm text-center text-gray-400">
            Don&#x27;t have an account yet?{" "}
            <Link
              to="/auth/sign-up"
              className="text-blue-500 focus:outline-none focus:underline hover:underline"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
