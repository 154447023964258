import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaBalanceScale } from "react-icons/fa";
import { TbSettingsStar } from "react-icons/tb";
import { HiLightBulb } from "react-icons/hi2";
import { PiHandFistBold } from "react-icons/pi";
import { RiHandCoinFill } from "react-icons/ri";

const CoreValues = () => {
  const testimonialsData = [
    {
      icon: <FaBalanceScale />,
      name: "Balance",
      bgColor: "#FFC6DB",
      content:
        "We strive for balance in all aspects of our work, ensuring equitable solutions for our clients, Partners, and team members.",
    },
    {
      icon: <TbSettingsStar />,
      name: "Agility",
      bgColor: "#FFEFBE",
      content:
        "We are responsive to the evolving market dynamics and client needs, ensuring we remain relevant and effective in our services.",
    },
    {
      icon: <HiLightBulb />,
      name: "Novelty",
      bgColor: "#D1EAFE",
      content:
        "We embrace innovation, through collaboration and technologically driven solutions while continuously seeking new and creative ways to satisfy our clients' evolving individual insurance needs.",
    },
    {
      icon: <PiHandFistBold />,
      name: "Boldness",
      bgColor: "#D1FEDD",
      content:
        "We lead with boldness and courage in exploring new opportunities, challenging conventions by delivering groundbreaking solutions to ensure our clients enjoy the peace of mind they truly deserve.",
    },
    {
      icon: <RiHandCoinFill />,
      name: "Ownership",
      bgColor: "#E3D1FE",
      content:
        "We take ownership of our responsibilities and commitments to establish mutually beneficial and life-long partnership with our clients, delivering services at the highest level of professionalism.",
    },
  ];
  return (
    <div>
      <section className=" bg-white ">
        <div className="max-w-[90rem] mx-auto">
          <h1
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
            className="text-3xl px-6 text-center md:text-4xl font-bold pb-8 text-[#003862] "
          >
            Our Core Values
          </h1>
          <Swiper
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-once="true"
            spaceBetween={20}
            loop={true}
            slidesPerView={3}
            navigation={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination, Autoplay]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              375: {
                slidesPerView: 1,
              },
              575: {
                slidesPerView: 1,
              },
              767: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            className="mySwiper"
          >
            {testimonialsData.map((data, index) => (
              <SwiperSlide key={index}>
                <div className=" bg-[#F9F9F9] flex flex-col justify-center h-[25rem] lg:h-[20rem] my-auto rounded-3xl ">
                  <div className="text-4xl  px-10 text-[#003862] ">
                    <div
                      className="w-12 h-12 mb-4 p-2 rounded-full"
                      style={{ backgroundColor: data.bgColor }}
                    >
                      {data.icon}
                    </div>

                    <h1 className=" font-bold text-2xl text-[#003862]">
                      {data.name}
                    </h1>
                    <p className=" py-2 text-base font-medium text-[#003862]">
                      {data.content}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default CoreValues;
