export const MotorVehicleRequest = {
  motorvehiclefields: [
    {
      label: "Company Name",
      name: "companyName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Upload registration file",
      name: "regFile",
      type: "file",
      required: true,
    },
  

  ],
};
