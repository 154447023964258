import React from "react";
import { FaLinkedin } from "react-icons/fa";

const OurPeople = () => {
  const BoardMembers = [
    {
      name: "Gideon Adjah",
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720614968/gideon_gg2wmo.webp",
      portfolio: "https://www.linkedin.com/in/gideon-adjah-88818631",
    },
    {
      name: "Kofi Owusu-Nhyira",
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720614971/kofi_njaq9a.webp",
      portfolio: "https://www.linkedin.com/in/kofiowusu-nhyira/",
    },
    {
      name: "Priscilla Hazel",
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720614969/priscilla_ekakbz.webp",
      portfolio: "https://www.linkedin.com/in/priscahazel",
    },
  ];

  const ManagementMembers = [
    {
      name: "Edith Lawson-Atenga",
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720614967/edith_mh7nbq.webp",
      portfolio: "https://www.linkedin.com/in/edith-lawson-atenga-4ab4a92a",
    },
    {
      name: "Sefakor Amesimeku",
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720614971/sefa_k7djoz.webp",
      portfolio:
        "https://www.linkedin.com/in/daisy-ann-esinam-anatsui-a132aa84",
    },
    {
      name: "Daisy Anatsui",
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720614968/member_o40j7q.webp",
      portfolio:
        "https://www.linkedin.com/in/daisy-ann-esinam-anatsui-a132aa84",
    },
    {
      name: "Anthony Sedjoah ",
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720614972/tony_uwbqdr.webp",
      portfolio: "https://www.linkedin.com/in/anthony-s-799692b9",
    },
  ];
  return (
    <div>
      <div className="px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-[90rem] md:px-24 lg:px-8 ">
        <h1
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="true"
          className="text-3xl px-6 md:text-4xl font-bold mb-8 text-[#003862] "
        >
          Our People
        </h1>
        <h1
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="400"
          data-aos-duration="1000"
          data-aos-once="true"
          className="text-2xl px-6 font-bold my-8 text-center text-[#003862] "
        >
          Board Members
        </h1>
        <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-3 justify-center lg:max-w-screen-md text-[#003862]">
          {BoardMembers.map((data, index) => (
            <div
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay={index * 200}
              key={index}
            >
              <div className="relative mb-4">
                <img
                  className=" object-cover rounded-3xl transition-all duration-300 hover:scale-110"
                  src={data.image}
                  alt="Person"
                />
              </div>
              <a href={data.portfolio}>
                <div className="flex flex-row sm:text-center items-center justify-between">
                  <p className="text-base font-bold">{data.name}</p>
                  <FaLinkedin className="text-lg " />
                </div>
              </a>
            </div>
          ))}
        </div>
        <h1
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="true"
          className="text-2xl px-6 font-bold my-8 text-center text-[#003862] "
        >
          Management
        </h1>
        <div className="grid gap-10 mx-auto sm:grid-cols-2 mb-5 lg:grid-cols-4 lg:max-w-screen-lg text-[#003862]">
          {ManagementMembers.map((data, index) => (
            <div
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay={index * 200}
              key={index}
            >
              <div className="relative mb-4">
                <img
                  className=" object-cover rounded-3xl transition-all duration-300 hover:scale-110"
                  src={data.image}
                  alt="Person"
                />
              </div>
              <a href={data.portfolio}>
                <div className="flex flex-row sm:text-center items-center justify-between">
                  <p className="text-base font-bold">{data.name}</p>
                  <FaLinkedin className="text-lg " />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurPeople;
