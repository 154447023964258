import React from "react";

const MissionVision = () => {
  return (
    <div>
      <section className="p-8 md:p-10">
        <div className="mx-auto max-w-[90rem] space-y-8 md:space-y-20">
          {/* 1/3 and 2/3 */}
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <div
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos-once="true"
              className="flex flex-col rounded-3xl p-5  lg:p-10 bg-[#f9f9f9]"
            >
              <h2 className="text-2xl font-medium text-[#003862] md:text-3xl">
                To become the go-to insurance solutions provider, leading in
                Africa with significant impact in Ghana.
              </h2>
              <p className="mt-4 font-bold text-base">Vision Statement</p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="400"
              data-aos-duration="1000"
              data-aos-once="true"
              className="rounded-3xl bg-[#f9f9f9] p-5 lg:p-10 md:col-span-2"
            >
              <h2 className="text-2xl font-medium text-[#003862] md:text-3xl">
                To build a financially confident and resilient community by
                delivering exceptional Insurance solutions and bespoke support
                through technology, disruptive Innovation and continuous
                learning.
              </h2>
              <p className="mt-4 font-bold text-base">Mission Statement</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MissionVision;
