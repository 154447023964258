import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { ArrowRight } from "../../../../../assets";

const BookMeeting = ({ modalImage }) => {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <div className="">
        <button
          onClick={openModal}
          className="mt-3 inline-flex  underline items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-[#1A99FA] hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none"
        >
          Book a meeting
          <img src={ArrowRight} alt="" />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 flex min-h-screen items-end justify-center overflow-hidden px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-flex w-full z-50 transform flex-col overflow-x-hidden overflow-y-auto rounded-xl bg-[#F2F2F2] text-left align-bottom shadow-2xl transition-all sm:my-8 sm:max-w-4xl sm:align-middle">
                <div className="absolute top-4 right-5">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="inline-flex aspect-square cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text child-svg:h-5 child-svg:w-5"
                  >
                    <span className="sr-only">Close</span>
                    <IoCloseCircle className="h-5 w-5" />
                  </button>
                </div>


                <div className="md:grid md:grid-cols-3 md:items-center ">
                  <div className="col-span-1">
                    <img
                      className="rounded-s-xl object-cover md:h-[90vh] lg:h-[80vh]"
                      src={modalImage || "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610407/purchase_z0nwih.webp"}
                      alt=""
                    />
                  </div>

                  <div className="mt-5 col-span-2 sm:mt-10 lg:mt-0">
                    <div className="space-y-6 sm:space-y-8">
                      <div className="p-4 md:p-5 md:px-16">
                        <h1 className="mb-1 text-[#2D2D2D] text-2xl font-bold">
                          We can't wait to meet you
                        </h1>
                        <p className="mb-5 text-[#2D2D2D] text-base font-medium">
                          Kindly book your meeting below
                        </p>
                        <div className="font-medium">
                          <div className="mb-2">
                            <input
                              type="text"
                              placeholder="Name"
                              className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9] "
                            />
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              placeholder="Phone"
                              className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9] "
                            />
                          </div>
                          <div className="mb-2">
                            <input
                              type="email"
                              placeholder="Email"
                              className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9] "
                            />
                          </div>
                          <div className="grid grid-cols-2 mb-2 gap-x-3">
                            <div className="mb-2">
                              <input
                                type="date"
                                placeholder="Date"
                                className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9] "
                              />
                            </div>
                            <div className="mb-2">
                              <input
                                type="time"
                                placeholder="Time"
                                className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9] "
                              />
                            </div>
                          </div>
                          <h1 className="mb-2 text-[#2D2D2D] ">
                            Select meeting type
                          </h1>
                          <div className="flex gap-3">
                            <div className="flex items-center space-x-3">
                              <input
                                name="from"
                                id="virtual"
                                type="radio"
                                className="h-5 w-5 border-2 border-text bg-transparent text-primary focus:ring-primary focus:ring-offset-0 checked:focus:ring-text disabled:opacity-30"
                              />
                              <label
                                htmlFor="virtual"
                                className="block text-sm font-semibold text-heading"
                              >
                                Virtual
                              </label>
                            </div>
                            <div className="flex items-center space-x-3">
                              <input
                                name="from"
                                id="onsite"
                                type="radio"
                                className="h-5 w-5 border-2 border-text bg-transparent text-primary focus:ring-primary focus:ring-offset-0 checked:focus:ring-text disabled:opacity-30"
                              />
                              <label
                                htmlFor="onsite"
                                className="block text-sm font-semibold text-heading"
                              >
                                In-Person
                              </label>
                            </div>
                          </div>
                        </div>
                        <button className="mt-3 inline-flex w-full underline items-center justify-end gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-[#1A99FA] hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none">
                          Book a meeting
                          <img src={ArrowRight} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default BookMeeting;
