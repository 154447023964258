export const homeOwnerRequest = {
  insuranceTypeEnum: "HOMEOWNERS",
  product: undefined,
  homeownerfields: [
    {
      label: "Name of proposer",
      name: "proposalName",
      type: "text",
      required: true,
    },

    { label: "Location ", name: "location", type: "text", required: true },
    {
      label: "Value of your building",
      name: "buildingValue",
      type: "number",
      required: true,
    },
    {
      label: "Value of your content",
      name: "itemValue",
      type: "number",
      required: true,
    },
    {
      label: "Type of cover",
      name: "coverType",
      type: "select",
      typeHasApiCall: false,
      required: true,
      options: [
        { key: "CONTENT", value: "Content" },
        { key: "BUILDING", value: "Building" },
        { key: "CONTENT_BUILDING", value: "Building and content" },
      ],
    },

    {
      label: "Valuation Report",
      name: "VR",
      type: "file",
      required: true,
    },
    {
      label: "List of Content",
      name: "listOfContent",
      type: "file",
      required: true,
    },
  ],
};
