import React, { useEffect } from "react";
import "./App.css";
import Startup from "./utils/Startup";
import AOS from "aos";
import SiteUnderConstruction from "./presentation/pages/SiteUnderConstruction";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      {/* <SiteUnderConstruction /> */}
      <Startup />
    </div>
  );
};

export default App;
