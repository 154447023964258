export const AssetsAllRisksRequest = {
  assetsrisksfields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Value of Building",
      name: "valueOfBuilding",
      type: "text",
      required: true,
    },
    {
      label: "Value of Equipment",
      name: "valueOfEquipment",
      type: "text",
      required: true,
    },
    {
      label: "Type of Stock",
      name: "typeOfStock",
      type: "text",
      required: true,
    },
    {
      label: "Value of stock",
      name: "valueOfStock",
      type: "text",
      required: true,
    },
  ],
};
