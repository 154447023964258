import React, { useState } from "react";
import ClaimVoiceRecorder from "./ClaimVoiceRecorder";
import { ArrowRight } from "../../../../assets";
import { RiCameraLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

const ClaimCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedClaim, setSelectedClaim] = useState("");
  const [token, setToken] = useState(sessionStorage.getItem("access_token"));
  const handleClaimChange = (e) => {
    setSelectedClaim(e.target.value);
  };

  const handleLoginRedirect = () => {
    sessionStorage.setItem("previousLocation", location.pathname);
    navigate("/auth/login");
  };

  return (
    <div className=" pt-28 pb-10 mx-4">
      <div className="flex flex-col bg-[#F2F2F2] max-w-lg mx-auto border shadow-sm rounded-2xl">
        <div className="flex justify-between bg-[#003862] items-center text-white rounded-t-2xl py-3 px-4 md:px-5">
          <h3 className="text-lg font-bold">
            Policy claim form{" "}
            {!token && (
              <div className="space-y-2 inline-flex">
                <p className="  text-sm font-medium  text-white ">
                  (Please login to proceed!!{" "}
                  <span
                    onClick={handleLoginRedirect}
                    className="font-bold underline text-[#31A5F8] hover:text-sky-600 cursor-pointer"
                  >
                    Login)
                  </span>
                </p>
              </div>
            )}
          </h3>

          {/* <div className="flex items-center gap-x-1">
            <p>Third party</p>
          </div> */}
        </div>
        <div className="p-4 md:p-5">
          <h1 className="mt-3 text-[#2D2D2D] text-xl font-bold">
            Kindly provide the following details
          </h1>
          <p className="my-3 text-[#2D2D2D] text-md font-bold">
            Kindly select the type of claim
          </p>
          <div className="mb-2">
            <select
              className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
              value={selectedClaim}
              disabled={!token}
              onChange={handleClaimChange}
            >
              <option value="" selected>
                Select claim
              </option>
              <option value="LIFE">Life</option>
              <option value="FIRE">Fire</option>
              <option value="MOTOR">Motor</option>
              <option value="HEALTH">Health</option>
              <option value="MARINE">Marine</option>
              <option value="PERSONAL_ACCIDENT">Personal accident</option>
            </select>
          </div>

          {selectedClaim === "LIFE" && (
            <>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Date of Death
                </label>
                <input
                  type="date"
                  placeholder="date of incident"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>

              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Name of insured
                </label>
                <input
                  type="text"
                  placeholder=""
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Policy Number
                </label>
                <input
                  type="number"
                  placeholder=""
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
                <div className="mb-2">
                  <label
                    htmlFor="phoneNumber"
                    className="text-sm text-gray-500"
                  >
                    Record audio of the incident
                  </label>
                  <ClaimVoiceRecorder />
                </div>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Identification Card
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Upload death certificate
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    disabled={!token}
                    id="uploadFile2"
                    className="hidden"
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Upload medical report
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile2"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Upload police report
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile2"
                    className="hidden  disabled={!token}"
                  />
                </label>
              </div>
            </>
          )}

          {selectedClaim === "FIRE" && (
            <>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Date of damage or loss
                </label>
                <input
                  type="date"
                  placeholder="date of incident"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Time of damage or loss
                </label>
                <input
                  type="time"
                  placeholder="date of incident"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>

              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Location of damage or loss
                </label>
                <input
                  type="text"
                  placeholder=""
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Cause of damage or loss
                </label>
                <textarea
                  placeholder=""
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
                <div className="mb-2">
                  <label
                    htmlFor="phoneNumber"
                    className="text-sm text-gray-500"
                  >
                    Record audio of the incident
                  </label>
                  <ClaimVoiceRecorder />
                </div>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Pictures of damage
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Police report
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Fire report
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
            </>
          )}
          {selectedClaim === "HEALTH" && (
            <>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Claim description
                </label>
                <textarea
                  placeholder="Tell us why you paid for the service"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Record audio of the incident
                </label>
                <ClaimVoiceRecorder />
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Receipt of Medical bills
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile1"
                    disabled={!token}
                    className="hidden"
                  />
                </label>
              </div>

              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Identification Card
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload Photo
                  <input
                    type="file"
                    id="uploadFile2"
                    disabled={!token}
                    className="hidden"
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Insurance Card
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile2"
                    disabled={!token}
                    className="hidden"
                  />
                </label>
              </div>
            </>
          )}
          {selectedClaim === "MOTOR" && (
            <>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Date of Incident
                </label>
                <input
                  type="date"
                  placeholder="date of incident"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl  bg-[#E9E9E9]"
                />
              </div>

              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Repair Estimate
                </label>
                <input
                  type="text"
                  placeholder="Eg. GHS3000"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Incident description
                </label>
                <textarea
                  disabled={!token}
                  placeholder="Give a brief description of the incident"
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Record audio of the incident
                </label>
                <ClaimVoiceRecorder />
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Pictures of the damage vehicle (showing vehicle number)
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>

              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Driver's license at the time of the incident (front and back)
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile2"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Police report of the incident
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile2"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Upload pro-forma invoice
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile2"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
            </>
          )}
          {selectedClaim === "PERSONAL_ACCIDENT" && (
            <>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Date of Accident
                </label>
                <input
                  type="date"
                  placeholder="date of incident"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>

              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Name of insured
                </label>
                <input
                  type="text"
                  placeholder=""
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Policy Number
                </label>
                <input
                  type="number"
                  placeholder=""
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Record audio of the incident
                </label>
                <ClaimVoiceRecorder />
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Identification Card
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Upload medical report
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
            </>
          )}

          {selectedClaim === "MARINE" && (
            <>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Policy name / Policy number
                </label>
                <input
                  type="text"
                  placeholder=""
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Date of damage or loss
                </label>
                <input
                  type="date"
                  disabled={!token}
                  placeholder="date of incident"
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Time of damage or loss
                </label>
                <input
                  type="time"
                  placeholder="date of incident"
                  disabled={!token}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Loss description
                </label>
                <textarea
                  disabled={!token}
                  placeholder="Give a brief description of the loss"
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-xl bg-[#E9E9E9]"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phoneNumber" className="text-sm text-gray-500">
                  Record audio of the incident
                </label>
                <ClaimVoiceRecorder />
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Pictures of damage
                </p>
                <label
                  htmlFor="uploadFile2"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile2"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>

              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Original Bill of lading and/or other contract of carriage
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload photo
                  <input
                    type="file"
                    id="uploadFile1"
                    disabled={!token}
                    className="hidden"
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Condition of the goods on arrival at the harbour
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
              <div className="mb-3">
                <p className="text-base font-bold text-black mb-2">
                  Upload goods invoice
                </p>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#E9E9E9] hover:bg-[#dbdada] text-black text-sm w-40 px-4 py-2 rounded-xl outline-none cursor-pointer"
                >
                  <RiCameraLine className="my-auto" />
                  Upload file
                  <input
                    type="file"
                    id="uploadFile1"
                    className="hidden"
                    disabled={!token}
                  />
                </label>
              </div>
            </>
          )}

          <button
            disabled={!token}
            className="mt-3 inline-flex w-full justify-end underline items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-[#1A99FA] hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none"
          >
            Make a claim
            <img src={ArrowRight} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClaimCard;
