import React from "react";

const GetSocialWithUs = () => {
  const slidesData = [
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610391/banbo-tip1_e9qzqt.webp",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610396/banbo-tip2_xlag6q.webp",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610394/banbo-tip3_gbqjnt.webp",
    },
  ];
  return (
    <div className="col-span-1">
      <h1 className="text-2xl mb-4 font-bold">Get social with us</h1>

      <img
        className="rounded-2xl object-cover"
        src="https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610391/banbo-tip1_e9qzqt.webp"
        alt=""
      />
    </div>
  );
};

export default GetSocialWithUs;
