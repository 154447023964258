import React from "react";
import { CiPower } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";
import { SanlamAllianz } from "../../../../../assets";

const ShopSolidSidebar = () => {
  const policyInfo = [
    {
      content:
        "Loss or damage to pharmaceutical products in the shop/warehouse",
    },
    {
      content:
        "Damage to the property of customers within the premises of the shop",
    },
    {
      content: "Covers personal accident cover of GH¢2,000",
    },
  ];

  const underwritten = [
    {
      logo: SanlamAllianz,
    },
  ];
  return (
    <div className="bg-[#003862] lg:col-span-1 rounded-3xl">
      <div className="  text-white p-8  my-4 ">
        <p className="text-base mb-6">
          Policy covers the risk of loss or damage to stocks and the liability
          of business owners towards clients while on their property. This
          product also offers personal Accident cover over the business owner
          within the period
        </p>
        <div className="w-full max-w-lg divide-y divide-layer-2 divide-opacity-20 divide-[#003862]">
          <h5 className="mb-6 text-2xl font-bold leading-none ">
            Policy Information
          </h5>
          {policyInfo.map((data, index) => (
            <div key={index} className="block py-2 ">
              <div className="flex items-center justify-between space-x-4">
                <GoDotFill className=" text-heading text-[#1A9AFB]" />
                <h4 className="flex-1 text-sm  text-heading ">
                  {data.content}
                </h4>
              </div>
            </div>
          ))}
          <div className="pt-5">
            <p className="text-white text-xs pt-4 text-center underline">
              Terms and conditions may apply
            </p>
            <p className="text-white text-xs text-center pt-4 underline">
              Underwritten by
            </p>
            <div className="grid items-center mt-2  gap-2">
              {underwritten.map((data, index) => (
                <img
                  key={index}
                  className="mx-auto w-32  bg-white rounded-full p-2"
                  src={data.logo}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopSolidSidebar;
