import React from "react";
import {
  ArrowRightUp,
} from "../../../../../assets";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const Products = () => {
  const productData = [
    {
      image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610395/family_l3zwbf.webp",
      color: "#D1EAFE",
      title: "Individual & Family",
      body: "Protecting your tomorrow, today.",
      link: "/buy-insurance/individual-family",
    },
    {
      image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610391/business_d42j5g.webp",
      color: "#FFC6DB",
      title: "Business Protection",
      body: "Empowering Businesses, protecting your success",
      link: "/buy-insurance/business-protection",
    },
    {
      image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610390/chess_frhyfy.webp",
      color: "#FFEFBE",
      title: "Special Protection",
      body: "Innovative coverage tailored just for You",
      link: "/buy-insurance/special-protection",
    },
    {
      image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610407/team_al6frm.webp",
      color: "#FFB9B9",
      title: "Group Protection",
      body: "Strength in unity, coverage for All.",
      link: "/buy-insurance/group-protection",
    },
  ];
  return (
    <div className="">
      <Swiper
        spaceBetween={10}
        loop={true}
        slidesPerView={3}
        navigation={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        breakpoints={{
          0: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          375: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          575: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          767: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
        }}
        className="mySwiper"
      >
        <div>
          {productData.map((slide, index) => (
            <SwiperSlide key={index}>
              <Link to={slide.link}>
                <div
                  style={{ backgroundColor: slide.color }}
                  className="max-w-md rounded-3xl group"
                >
                  <div className="overflow-hidden relative">
                    <img
                      src={slide.image}
                      className="aspect-video w-full rounded-t-3xl object-cover transition-all duration-300 group-hover:scale-105"
                      alt=""
                    />
                  </div>{" "}
                  <div className="relative">
                    <div
                      style={{ borderColor: slide.color }}
                      className="absolute -bottom-6 right-10 rounded-full border-4 w-16 h-16 bg-[#003862]"
                    >
                      <img
                        className="transition-all duration-300 group-hover:rotate-45 pl-[15px] w-12 h-12 "
                        src={ArrowRightUp}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="p-6">
                    <h3 className="text-3xl pt-6 pb-2 font-bold text-[#003862]">
                      {slide.title}
                    </h3>
                    <p className="mt-1 text-base font-medium pb-6 text-[#003862]">
                      {slide.body}
                    </p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default Products;
