import React, {
  ChangeEvent,
  useState,
  useRef,
  ChangeEventHandler,
} from "react";
import { LogoDark } from "../../../assets";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOtpRequest } from "../../../../models/request/auth-request";
import { useVerifyOtpMutation } from "../../../../services/auth-service";
import Loader from "../../../components/Loader";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const otpID = location.state?.id;
  const [otpValues, setOtpValues] = useState<string[]>(Array(6).fill(""));

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = e.target;
    const index = parseInt(name.split("-")[1]);

    if (value.match(/^[0-9]$/) || value === "") {
      setOtpValues((prevOtpValues) => {
        const newOtpValues = [...prevOtpValues];
        newOtpValues[index] = value;
        return newOtpValues;
      });

      if (value.length === 1 && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const [userVerify, { isLoading }] = useVerifyOtpMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData: verifyOtpRequest = {
      id: otpID,
      value: otpValues.join(""),
    };

    try {
      const response = await userVerify(formData)
      const { code, message, data } = response.error ? response.error["data"] : response["data"]
      if (code === "00") {
        toast.success(message);
        navigate("/auth/verify-user");
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <div className="h-[100vh] flex justify-center items-center">
      <div className="block max-w-md p-8 bg-[#F8F9FA] rounded-3xl">
        <img src={LogoDark} className="mx-auto w-28" alt="Logo" />

        <div className="my-6">
          <p className=" text-sm text-gray-500 text-center mb-3">
            Enter code sent to your phone number
          </p>
          <form onSubmit={handleFormSubmit}>
            <div className="flex flex-row items-center justify-between mx-auto w-full">
              {[...Array(6)].map((_, index) => (
                <div className="w-14 h-14 mx-1" key={index}>
                  <input
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type="text"
                    name={`value-${index}`}
                    maxLength={1}
                    onChange={handleInputChange}
                    value={otpValues[index]}
                    required
                  />
                </div>
              ))}
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full px-4 py-3 mt-5 tracking-wide rounded-lg text-white transition-colors duration-200 transform bg-[#1B8ADB] hover:bg-[#125182] focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              {isLoading ? (
                <p className="mx-auto text-center">
                  <Loader />
                </p>
              ) : (
                "Verify"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
