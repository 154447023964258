export const ContractorRiskRequest = {
  contractorsriskfields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Name of Company",
      name: "nameOfCompany",
      type: "text",
      required: true,
    },
    {
      label: "Address of Company",
      name: "addressOfCompany",
      type: "text",
      required: true,
    },
    {
      label: "Description of Projects",
      name: "projectDescription",
      type: "textarea",
      as: "textarea",
      required: false,
    },
    {
      label: "Value of project",
      name: "valueOfProject",
      type: "text",
      required: true,
    },
    {
      label: "Duration of project",
      name: "durationOfProject",
      type: "text",
      required: true,
    },
    {
      label: "Location",
      name: "siteLocation",
      type: "text",
      required: true,
    },
  ],
};
