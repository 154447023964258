export const plannersProtectionRequest = {
  insuranceTypeEnum: "PLANNERSPROTECTION",
  product: undefined,
  plannersprotectionfields: [
    {
      label: "Name of business",
      name: "business-name",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phone-number",
      type: "text",
      required: true,
    },
    { label: "Location", name: "location", type: "text", required: true },
    {
      label: "Upload a list of equipment",
      name: "equipment-list",
      type: "file",
      required: true,
    },
    {
      label: "Number of employees",
      name: "num-employees",
      type: "number",
      required: true,
    },
  ],
};
