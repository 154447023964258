export const GroupLifeRequest = {
  grouplifefields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Name of group/business",
      name: "nameOfbusiness",
      type: "text",
      required: true,
    },
    {
      label: "Address of group/business",
      name: "addressOfbusiness",
      type: "text",
      required: true,
    },
    {
      label: "Nature of group/business",
      name: "natureOfBusiness",
      type: "textarea",
      as: "textarea",
      required: true,
    },

    {
      label: "Number of employees or members",
      name: "numberOfEmployees",
      type: "text",
      required: true,
    },
    {
      label: "Age Range",
      name: "ageRange",
      type: "text",
      required: true,
    },
  ],
};
