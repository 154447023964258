import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogoDark } from "../../../assets";
import { createPasswordRequest } from "../../../../models/request/auth-request";
import { useCreatePasswordMutation } from "../../../../services/auth-service";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useForm } from "react-hook-form";

const CreatePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const phone = location.state?.msisdn || location.state?.phoneNumber;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState<createPasswordRequest>({
    msisdn: `233${phone}`,
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [userCreatePassword, { isLoading }] = useCreatePasswordMutation();

  const handleFormSubmit = async (e) => {
    // e.preventDefault();
    try {
      const response = await userCreatePassword(formData);
      const { code, message, data } = response.error ? response.error["data"] : response["data"]
      if (code === "00") {
        toast.success(message);
        navigate("/auth/login");
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <div>
      <div className="h-[100vh] flex justify-center items-center">
        <div className="block max-w-md p-8 bg-[#F8F9FA] rounded-3xl">
          <a href="/">
            <img src={LogoDark} className="mx-auto w-28" alt="" />
          </a>
          <p className="mt-3 text-sm text-center text-gray-400">
            Customer has not been activated. Please create a password to
            activated your account
          </p>
          <div className="my-6">
            <div className="mb-3">
              <label htmlFor="phoneNumber" className=" text-sm text-gray-500  ">
                Phone Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                  <p className="text-xs font-medium">+233</p>
                </div>
                <input
                  type="text"
                  className="block w-full border-0 p-4 ps-12 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                  placeholder="50xxxxxxx"
                  value={phone}
                  name="msisdn"
                  onChange={handleInputChange}
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="phoneNumber" className=" text-sm text-gray-500  ">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Za-z0-9]).{8,}$/,
                      message:
                        "It should have at least 8 characters and special character",
                    },
                  })}
                  onChange={handleInputChange}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                />
                {showPassword ? (
                  <FiEyeOff
                    className="absolute end-2.5 bottom-[0.95rem] text-lg"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <FiEye
                    className="absolute end-2.5 bottom-[0.95rem] text-lg"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
              <p className="text-red-500 text-xs">
                {errors?.password?.message?.toString()}
              </p>
            </div>

            <div className="mb-4">
              <label htmlFor="phoneNumber" className=" text-sm text-gray-500  ">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === getValues("password") ||
                      "The passwords do not match",
                  })}
                  onChange={handleInputChange}
                  className="block w-full  p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                />
                {showConfirmPassword ? (
                  <FiEyeOff
                    className="absolute end-2.5 bottom-[0.95rem] text-lg"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                ) : (
                  <FiEye
                    className="absolute end-2.5 bottom-[0.95rem] text-lg"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                )}
              </div>
              <p className="text-red-500 text-xs">
                {errors?.confirmPassword?.message?.toString()}
              </p>
            </div>
            <button
              disabled={
                !formData.password ||
                !formData.confirmPassword ||
                !formData.msisdn
              }
              onClick={handleSubmit(handleFormSubmit)}
              className="w-full px-4 py-3 tracking-wide rounded-lg text-white transition-colors duration-200 transform bg-[#125182] hover:bg-[#215CA4] focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              {isLoading ? (
                <p className="mx-auto text-center">
                  <Loader />
                </p>
              ) : (
                "Create Password"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
