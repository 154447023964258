import React, { useEffect, useState } from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { NavLink, Outlet, parsePath, useLocation } from "react-router-dom";
import { productType } from "../../../../../models/request/buy-insurance-request";

const BuyInsurance = () => {
  const location = useLocation();
  const productData = [
    {
      title: "Individual & Family",
      link: "/buy-insurance/individual-family",
    },
    {
      title: "Business Protection",
      link: "/buy-insurance/business-protection",
    },
    {
      title: "Special Protection",
      link: "/buy-insurance/special-protection",
    },
    {
      title: "Group Protection",
      link: "/buy-insurance/group-protection",
    },
  ];
  const [selectedDetails, setSelectedDetails] = useState<any>({});
  const getPlanDetails = (path) => {
    switch (path) {
      case "/buy-insurance/individual-family":
        return {
          image:
            "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610395/family_l3zwbf.webp",
          title: "Individual & Family",
          body: "Protecting your tomorrow, today.",
        };
      case "/buy-insurance/business-protection":
        return {
          image:
            "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610391/business_d42j5g.webp",
          title: "Business Protection",
          body: "Empowering Businesses, protecting your success",
        };
      case "/buy-insurance/special-protection":
        return {
          image:
            "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610390/chess_frhyfy.webp",
          title: "Special Protection",
          body: "Innovative coverage tailored just for You",
        };
      case "/buy-insurance/group-protection":
        return {
          image:
            "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610407/team_al6frm.webp",
          title: "Group Protection",
          body: "Strength in unity, coverage for All.",
        };
      default:
        return {
          image:
            "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720609758/carousel1_fp0bk6.webp",
          title: "Our Product",
          body: "Buy insurance the smart way!",
        };
    }
  };

  useEffect(() => {
    const details = getPlanDetails(location.pathname);
    setSelectedDetails(details);
  }, [location.pathname]);

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${selectedDetails.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-[#003862] h-[60vh] w-full flex items-center justify-center bg-opacity-80">
          <div className="px-4 sm:px-6 lg:px-8 py-10 space-y-8">
            <div className="max-w-5xl text-center mx-auto">
              <h1 className="block font-bold text-gray-200 text-4xl sm:text-5xl">
                {selectedDetails.title}
              </h1>
              <p className="text-white text-lg py-2">{selectedDetails.body}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-[90rem] md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-evenly lg:flex-row">
          <div className="max-w-2xl bg-[#D1EAFE] p-8 rounded-3xl my-4">
            <h5 className="mb-1 text-2xl font-semibold leading-none">
              Choose your insurance plan today!
            </h5>
            <p className="mb-6 text-xs text-gray-900">
              Need help?{" "}
              <span className="text-[#31A5F8] font-semibold underline">
                send us a Hi!
              </span>
            </p>
            <div className="w-full max-w-lg divide-y divide-layer-2 divide-opacity-20 divide-[#003862]">
              {productData.map((data, index) => (
                <div key={index} className="group">
                  <NavLink
                    to={data.link}
                    className={({ isActive }) =>
                      `py-4 group flex items-center justify-between space-x-4 group-hover:text-xl group-hover:font-bold ${
                        isActive ? "font-bold text-xl" : ""
                      }`
                    }
                  >
                    <HiOutlineArrowLongRight className="h-6 w-6" />
                    <span className="flex-1">{data.title}</span>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>

          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default BuyInsurance;
