import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import {
  AirtelTigo,
  ArrowRight,
  HomeOwnerPlanIcon,
  Mastercard,
  Mtn,
  Visa,
  Vodafone,
} from "../../../../../assets";
import { useMakePaymentsMutation } from "../../../../../../services/quote-service";
import toast from "react-hot-toast";

const SchoolInsuranceConfirmationModal = ({
  isOpen,
  onClose,
  policyResponse,
  formData,
}) => {
  const [userFullName, setUserFullName] = useState(
    sessionStorage.getItem("userFullName")
  );
  const [userMsisdn, setUserMsisdn] = useState(
    sessionStorage.getItem("userMsisdn")
  );
  const [userEmail, setUserEmail] = useState(
    sessionStorage.getItem("userEmail")
  );
  const [userDOB, setUserDOB] = useState(sessionStorage.getItem("userDOB"));
  const [accountData, setAccountData] = useState<any>({
    accountNumber: "n/a",
    quoteId: policyResponse?.quote?.id,
  });
  const [makePayment] = useMakePaymentsMutation();

  useEffect(() => {
    // Ensure accountData is updated when policyResponse changes
    setAccountData({
      accountNumber: "n/a",
      quoteId: policyResponse?.quote?.id,
    });
  }, [policyResponse]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await makePayment(accountData);
      console.log(response);
      const { code, message, data } = response.error
        ? response.error["data"]
        : response["data"];
      if (code === "00") {
        toast.success(message);
        const { checkout_url } = data[0].links;
        if (checkout_url) {
          window.open(checkout_url, "_blank"); // Opens the link in a new tab
        }
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 flex min-h-screen items-end justify-center overflow-hidden px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-flex w-full transform flex-col overflow-hidden rounded-3xl bg-white text-left align-bottom  transition-all sm:my-8 sm:max-w-3xl sm:align-middle">
                <div className="absolute top-4 right-5">
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex aspect-square cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text child-svg:h-5 child-svg:w-5"
                  >
                    <span className="sr-only">Close</span>
                    <IoCloseCircle className="h-5 w-5" />
                  </button>
                </div>

                <div className="flex h-16 flex-shrink-0 items-center justify-between px-6">
                  <Dialog.Title
                    as="h3"
                    className="text-lg flex items-center gap-2 font-bold"
                  >
                    <img
                      className="bg-[#E6E5E5] w-9 p-1.5 rounded-full"
                      src={HomeOwnerPlanIcon}
                      alt=""
                    />{" "}
                    {userFullName}'s HomeOwner Plan - Summary
                  </Dialog.Title>
                </div>

                <hr className="border-hr" />

                <div className="flex-1 px-6 py-5 sm:py-6">
                  <div className="grid md:grid-cols-2 mx-auto gap-10">
                    <div>
                      <h1 className="bg-[#E6E5E5] p-1 mb-2 text-sm font-bold ps-3 rounded-full">
                        Personal details
                      </h1>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Full name</h1>
                        <p className="text-sm font-bold">{userFullName}</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Phone nomber</h1>
                        <p className="text-sm font-bold">{userMsisdn}</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Date of birth</h1>
                        <p className="text-sm font-bold">{userDOB}</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Email</h1>
                        <p className="text-sm font-bold">{userEmail}</p>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <h1 className="bg-[#E6E5E5] p-1 ps-3 mb-2 text-sm  font-bold rounded-full">
                        Policy details
                      </h1>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Building Value</h1>
                        <p className="text-sm font-bold">
                          {
                            formData?.homeOwnersInsuranceRequestDto
                              ?.buildingValue
                          }
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Item Value</h1>
                        <p className="text-sm font-bold">
                          {formData?.homeOwnersInsuranceRequestDto?.itemValue}
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Location</h1>
                        <p className="text-sm font-bold">
                          {formData?.homeOwnersInsuranceRequestDto?.location}
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">
                          Nature of stock and goods
                        </h1>
                        <p className="text-sm font-bold">
                          {
                            formData?.homeOwnersInsuranceRequestDto
                              ?.natureOfStockAndGoods
                          }
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Proposal name</h1>
                        <p className="text-sm font-bold">
                          {
                            formData?.homeOwnersInsuranceRequestDto
                              ?.proposalName
                          }
                        </p>
                      </div>
                    </div>
                    <div>
                      <h1 className="bg-[#E6E5E5] p-1 mb-2 text-sm font-bold ps-3 rounded-full">
                        Quote details
                      </h1>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Cover Type</h1>
                        <p className="text-sm font-bold">
                          {policyResponse?.quote?.coverType}
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Created at</h1>
                        <p className="text-sm font-bold">
                          {policyResponse?.quote?.createdDate}
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">
                          Premium Currency
                        </h1>
                        <p className="text-sm font-bold">
                          {policyResponse?.quote?.premiumCurrency || "NA"}{" "}
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Product Name</h1>
                        <p className="text-sm font-bold">
                          {policyResponse?.quote?.product.name}
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Transfer Charge</h1>
                        <p className="text-sm font-bold">
                          {policyResponse?.quote?.transferCharge}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="border-hr" />
                <div className="px-6 pt-4">
                  {" "}
                  <div className="flex items-center mb-1 px-3 gap-2">
                    <h1 className="text-sm font-bold">Premium</h1>
                    <p className="text-sm font-normal">
                      (Transaction charges may apply)
                    </p>
                  </div>
                  <h1 className="text-2xl font-bold px-3">
                    {policyResponse?.currency} {policyResponse?.totalAmount}
                  </h1>
                  <div className="flex h-16 flex-shrink-0 items-center justify-between space-x-2">
                    <div className="flex gap-1 mb-3">
                      <img className="w-9 h-9" src={AirtelTigo} alt="" />
                      <img className="w-9 h-9" src={Mtn} alt="" />
                      <img className="w-9 h-9" src={Vodafone} alt="" />
                      <img className="w-9 h-9" src={Mastercard} alt="" />
                      <img className="w-9 h-9" src={Visa} alt="" />
                    </div>
                    <button
                      onClick={handleFormSubmit}
                      className=" flex underline group font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200"
                    >
                      Make payment
                      <span
                        aria-hidden="true"
                        className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                      >
                        <img src={ArrowRight} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SchoolInsuranceConfirmationModal;
