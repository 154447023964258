import { RxDashboard } from "react-icons/rx";
import { LogoDark } from "../assets";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoDocumentOutline } from "react-icons/io5";
import { GoArrowSwitch } from "react-icons/go";
import { TbLogout2 } from "react-icons/tb";
import { useContext } from "react";
import AuthContext from "../pages/userPortal/auth/utils/AuthContext";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Sidebar = ({ sideBar, setSideBar }) => {
  const { logout } = useContext(AuthContext);
  let navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/auth/login");
  };

  return (
    <nav
      className={`fixed md:relative z-20 h-full overflow-x-hidden overflow-y-auto transition-transform transform bg-white w-[250px] lg:w-[350px] ${
        sideBar ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0`}
    >
      <div className="flex justify-between sm:justify-center mx-auto items-center px-4 py-5">
        <a href="/">
          <img
            src={LogoDark}
            alt="banbo Logo"
            className="w-28 lg:w-36"
          />
        </a>
        <button
          className="md:hidden text-gray-500 focus:outline-none"
          onClick={() => setSideBar(false)}
        >
          <IoIosCloseCircleOutline className="w-8 h-8" />
        </button>
      </div>
      <nav
        className="text-base lg:mt-8 lg:bg-[#F9F9F9] lg:rounded-3xl lg:p-5 lg:mx-[3rem] text-black"
        aria-label="Main Navigation"
      >
        <NavLink
          className={({ isActive }) =>
            `flex items-center px-4 py-3 my-2 transition cursor-pointer ${
              isActive ? "bg-gray-200 rounded-xl" : ""
            }`
          }
          to="/dashboard"
        >
          <RxDashboard className="shrink-0 w-5 h-5 mr-2" />
          <span>Dashboard</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `flex items-center px-4 py-3 my-2 transition cursor-pointer ${
              isActive ? "bg-gray-200 rounded-xl" : ""
            }`
          }
          to="/my-policies"
        >
          <IoDocumentOutline className="shrink-0 w-5 h-5 mr-2" />
          <span>My Policies</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `flex items-center px-4 py-3 my-2 transition cursor-pointer ${
              isActive ? "bg-gray-200 rounded-xl" : ""
            }`
          }
          to="/activities"
        >
          <GoArrowSwitch className="shrink-0 w-5 h-5 mr-2" />
          <span>Activities</span>
        </NavLink>
        <button
          className="flex items-center px-4 py-3 my-2 transition cursor-pointer"
          onClick={handleLogout}
        >
          <TbLogout2 className="shrink-0 w-5 h-5 mr-2" />
          <span>Sign Out</span>
        </button>
      </nav>
    </nav>
  );
};

export default Sidebar;
