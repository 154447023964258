import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const TipsNews = () => {
  const slidesData = [
    {
      image:
        "https://images.unsplash.com/photo-1669828230990-9b8583a877ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1062&q=80",
      title:
        "Facebook is creating a news section in Watch to feature breaking news",
      description: "Facebook launched the Watch platform in August",
    },
    {
      image:
        "https://images.unsplash.com/photo-1669837401587-f9a4cfe3126e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
      title: "What CFR (Conversations, Feedback, Recognition) really is about",
      description: "For a lot of people these days, Measure What Matters.",
    },
  ];
  return (
    <div className="col-span-2">
      <h1 className="text-2xl mb-4 font-bold">Insurance tips/news</h1>
      <Swiper
        spaceBetween={30}
        loop={true}
        effect={"fade"}
        navigation={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="group relative block rounded-2xl">
              <div className="flex-shrink-0 relative rounded-2xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:z-[1] before:size-full before:bg-gradient-to-t before:from-gray-900/70">
                <img
                  className="size-full absolute top-0 start-0 object-cover"
                  src={slide.image}
                  alt=""
                />
              </div>

              <div className="absolute bottom-0 inset-x-0 z-10">
                <div className="flex flex-col h-full p-4 sm:p-6">
                  <h3 className="text-lg sm:text-3xl font-semibold text-white group-hover:text-white/80">
                    {slide.title}
                  </h3>
                  <p className="mt-2 text-white/80">{slide.description}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TipsNews;
