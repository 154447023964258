export const GroupTravelRequest = {
  grouptravelfields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Number of travelers",
      name: "numberOfTravelers",
      type: "number",
      required: true,
    },
    {
      label: "Duration of trips",
      name: "durationOfTrips",
      type: "text",
      required: true,
    },
    {
      label: "Destinations",
      name: "destinations",
      type: "text",
      required: true,
    },
  ],
};
