import React, { useState } from "react";

const Tooltip = ({ content, children, showTooltip }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="relative ">
      <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className=""
      >
        {children}
      </div>
      {showTooltip && visible && (
        <div
          role="tooltip"
          className="absolute z-10 inline-block px-3 py-2 text-xs  text-black transition-opacity duration-300 bg-[#f2f2f2] rounded-lg shadow-sm tooltip "
          style={{
            top: "-100%",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "-0.5rem",
          }}
        >
          {content}
          <div className="tooltip-arrow" data-popper-arrow />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
