import React, { useState } from "react";
import { useGetQuotesByUserIdQuery } from "../../../../../../services/portal-service";
import UpdatesActionsLoader from "./UpdatesActionsLoader";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import UpdatesActionsPagination from "./UpdatesActionsPagination";

const UpdatesActions = () => {
  const [storedUserId, setStoredUserId] = useState(
    sessionStorage.getItem("userId")
  );
  const userId = storedUserId ? parseInt(storedUserId) : 0;
  const { data: response, isLoading } = useGetQuotesByUserIdQuery(userId);
  const UserQuote = response?.data || [];

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 3;

  // Calculate total pages
  const totalPages = Math.ceil(UserQuote.length / pageSize);

  // Get current quotes to display
  const currentQuotes = UserQuote.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  if (isLoading) {
    return (
      <div>
        <UpdatesActionsLoader />
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-2xl font-semibold">Updates - Action needed</h1>
      <div className="grid gap-5  row-gap-5 sm:grid-cols-2  lg:grid-cols-3 my-4">
        {currentQuotes?.map((quote) => (
          <div
            key={quote.id}
            className="max-w-sm group bg-[#F9F9F9] hover:bg-[#D1EAFE] rounded-3xl p-6"
          >
            <div className="flex flex-wrap items-center mb-4">
              <img
                src={quote.product.icon}
                alt={quote.product.name}
                className="w-8 h-8 p-1.5 mr-2 bg-[#d2d1d1] rounded-full"
              />
              {quote.isDraft === true && quote.product !== null ? (
                <button
                  type="button"
                  className="inline-flex cursor-pointer items-center justify-center rounded-full  bg-[#1A9AFB] px-4 py-2 text-sm text-white  "
                >
                  Pending payment
                </button>
              ) : (
                <button
                  type="button"
                  className="inline-flex cursor-pointer items-center justify-center rounded-full  bg-[#22c55e] px-4 py-2 text-sm text-white  "
                >
                  Paid
                </button>
              )}
            </div>

            <div>
              <h6 className="mb-2 text-md font-bold leading-5">
                {quote.product.name}
              </h6>
              <p className="text-sm mb-6 text-gray-700">
                {quote.product.description}
              </p>
              {quote.isDraft === true ? (
                <button className=" flex items-center underline font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                  Make payment
                  <span
                    aria-hidden="true"
                    className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                  >
                    <HiOutlineArrowNarrowRight />
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
      <UpdatesActionsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default UpdatesActions;
