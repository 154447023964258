import React from "react";
import { CiPower } from "react-icons/ci";
import { GoDotFill, GoQuestion } from "react-icons/go";

const GroupLifeSidebar = () => {
  const policyInfo = [
    {
      content: "Death",
    },
    {
      content: "Disability",
    },
    {
      content: "Critical illness",
    },
  ];
  return (
    <div className="bg-[#003862] lg:col-span-1 rounded-3xl">
      <div className="text-white p-8 my-4 ">
        <p className="text-base mb-6">
          This policy provides cover for members of a registered group or
          employees that in the unlikely event of death, disability, or critical
          illness, a multiple of their annual salary will be provided to their
          beneﬁciaries as a replacement income.
        </p>
        <div className="w-full max-w-lg divide-y divide-layer-2 divide-opacity-20 divide-[#003862]">
          <h5 className="mb-6 text-2xl font-bold leading-none ">
            Policy Information
          </h5>
          {policyInfo.map((data, index) => (
            <div key={index} className="block py-2 ">
              <div className="flex items-center justify-between space-x-4">
                <GoDotFill className=" text-heading text-[#1A9AFB]" />
                <h4 className="flex-1 text-sm  text-heading ">
                  {data.content}
                </h4>
              </div>
            </div>
          ))}
          <div className="pt-5">
            <p className="text-white text-xs inline-flex items-center gap-x-4 underline">
              <GoQuestion className="text-base" /> Terms and conditions may
              apply
            </p>
            <p className="text-white text-xs inline-flex items-center gap-x-4 underline">
              <CiPower className="text-base" /> Underwritten by
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupLifeSidebar;
