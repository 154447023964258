import React from 'react'

const InsuranceShimmer = () => {
  return (
    <div>
       <div className="grid gap-5  row-gap-5 sm:grid-cols-2 my-4">
        {[1, 2, 3, 4].map((_, index) => (
          <div
            key={index}
            className="animate-pulse max-w-2xl w-72 h-48 group bg-[#f9f9f9] rounded-3xl p-6"
          >
            <div className="flex flex-row justify-evenly">
              {" "}
              <div className="flex items-center justify-center w-24 h-5 mr-2 rounded-full bg-gray-300"></div>
              <div>
                {" "}
                <div className="h-4 bg-gray-300 rounded mb-2"></div>
                <div className="h-3 bg-gray-300 rounded"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InsuranceShimmer