import React from "react";
import NavHeader from "../../../../components/NavHeader";
import { useLocation } from "react-router-dom";
import MotorSidebar from "./MotorPlan.tsx/MotorSidebar";
import HealthSidebar from "./healthPlan/HealthSidebar";
import TravelSidebar from "./TravelPlan.tsx/TravelSidebar";
import MotorInsuranceForm from "./MotorPlan.tsx/MotorInsuranceForm";
import TravelInsuranceForm from "./TravelPlan.tsx/TravelInsuranceForm";
import HomeOwnerSidebar from "./homeowners/HomeOwnerSidebar";
import HomeOwnerInsuranceForm from "./homeowners/HomeOwnerInsuranceForm";
import HealthInsuranceForm from "./healthPlan/HealthInsuranceForm";
import PharmaSecureSidebar from "./pharmaSecure/PharmaSecureSidebar";
import PharmaSecureForm from "./pharmaSecure/PharmaSecureForm";
import ShopSolidSidebar from "./shopSolid/ShopSolidSidebar";
import ShopSolidInsuranceForm from "./shopSolid/ShopSolidInsuranceForm";
import PlannersProtectionSidebar from "./plannersProtection/PlannersProtectionSidebar";
import PlannerProtectionForm from "./plannersProtection/PlannerProtectionForm";
import SchoolInsuranceSidebar from "./schoolInsurance/SchoolInsuranceSidebar";
import SchoolInuranceForm from "./schoolInsurance/SchoolInuranceForm";

const plans = {
  "/insurance-policy/Motor-Plan": {
    sidebar: <MotorSidebar />,
    form: <MotorInsuranceForm />,
    name: "Motor Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615431/img_motor_plan_icqsre.webp",
  },
  "/insurance-policy/Health-Plan": {
    sidebar: <HealthSidebar />,
    form: <HealthInsuranceForm />,
    name: "Health Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615386/img_health_Insurance_fuxaxg.webp",
  },
  "/insurance-policy/Travel-Plan": {
    sidebar: <TravelSidebar />,
    form: <TravelInsuranceForm />,
    name: "Travel Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615449/img_travel_icai9t.webp",
  },
  "/insurance-policy/Homeowners-Plan": {
    sidebar: <HomeOwnerSidebar />,
    form: <HomeOwnerInsuranceForm />,
    name: "Homeowners Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615386/img_homeowners_fnucyj.webp",
  },
  "/insurance-policy/PharmaSecure": {
    sidebar: <PharmaSecureSidebar />,
    form: <PharmaSecureForm />,
    name: "PharmaSecure",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615435/img_pharmasecure_oalhua.webp",
  },
  "/insurance-policy/ShopSolid": {
    sidebar: <ShopSolidSidebar />,
    form: <ShopSolidInsuranceForm />,
    name: "ShopSolid",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615449/img_shopsolid_mobwii.webp",
  },
  "/insurance-policy/Planners-Protection": {
    sidebar: <PlannersProtectionSidebar />,
    form: <PlannerProtectionForm />,
    name: "Planners Protection",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615438/img_planners_protection_bumsu6.jpg",
  },
  "/insurance-policy/School-Insurance": {
    sidebar: <SchoolInsuranceSidebar />,
    form: <SchoolInuranceForm />,
    name: "School Protection",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615448/img_school_insurance_fhan7f.webp",
  },
};

const PolicyPlan = () => {
  const { pathname } = useLocation();
  const plan = plans[pathname];

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${
            plan?.image ||
            "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615419/img_Life_InvestmentPlan_u9t99e.webp"
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-[#003862] h-[60vh] w-full flex items-center justify-center bg-opacity-80">
          <div className="px-4 sm:px-6 lg:px-8 py-10 space-y-8">
            <div className="text-center mx-auto">
              <h1 className="block font-bold text-gray-200 text-4xl sm:text-5xl">
                {plan ? plan.name : "This policy would be available soon"}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-6 md:py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-6xl ">
        <div className="grid gap-3 gap-x-8 sm:grid-cols-2 lg:grid-cols-3 px-2 py-4 items-stretch">
          {plan && (
            <>
              {plan.sidebar}
              {plan.form}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PolicyPlan;
