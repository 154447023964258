import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/app-constants";
import { BaseResponse } from "../models/response/base-response";

export const insurancePlansService = createApi({
  reducerPath: "insurancePlansService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),
  tagTypes: ["InsurancePlans"],
  endpoints: (build) => ({
    getAllInsurancePlans: build.query<BaseResponse<any[]>, void>({
      query: () => ({
        url: "/insurance/categories",
        method: "GET",
      }),
      providesTags: ["InsurancePlans"],
    }),
  }),
});

export const { useGetAllInsurancePlansQuery } = insurancePlansService;
