import React from "react";
import Hero from "./components/Hero";
import WhyChooseBanbo from "./components/WhyChooseBanbo";
import OurSolutions from "./components/OurSolutions/OurSolutions";
import OurPartnership from "./components/OurPartnership";
import Testimonials from "./components/Testimonials";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <WhyChooseBanbo />
      <OurSolutions />
      <OurPartnership />
      <Testimonials />
    </div>
  );
};

export default HomePage;
