import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/app-constants";
import { BaseResponse } from "../models/response/base-response";

export const portalService = createApi({
  reducerPath: "portalService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),
  tagTypes: ["Portal"],
  endpoints: (build) => ({
    getQuotesByUserId: build.query<BaseResponse<any[]>, number>({
      query: (userId) => ({
        url: `quotes/customer/${userId}`,
        method: "GET",
      }),
      providesTags: ["Portal"],
    }),
    getActivityByCustomerId: build.query<BaseResponse<any[]>, number>({
      query: (userId) => ({
        url: `/activities/customer/${userId}`,
        method: "GET",
      }),
      providesTags: ["Portal"],
    }),
  }),
});

export const { useGetQuotesByUserIdQuery, useGetActivityByCustomerIdQuery } = portalService;
