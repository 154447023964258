import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useVerifyUserByMsisdnMutation } from "../../../../services/auth-service";
import { LogoDark } from "../../../assets";
import Loader from "../../../components/Loader";

const VerifyUser = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [getUserByMsisdn, { data, isLoading }] =
    useVerifyUserByMsisdnMutation();

  const handleVerifyClick = async () => {
    try {
      const response = await getUserByMsisdn(`233${phoneNumber}`);
      const { code, message, data } = response.error ? response.error["data"] : response["data"]
      if (code === "00") {
        toast.success("Verification successful");
        const { activated } = data[0];
        if (activated === true) {
          setIsActive(true);
        } else {
          navigate("/auth/create-password", {
            state: { phoneNumber },
          });
        }
      } else {
        toast.error(message);
      }
    } catch (err) {
      toast.error("Error");
    }
  };

  return (
    <div>
      {!isActive && (
        <div className="h-[100vh] flex justify-center items-center">
          <div className="block max-w-md p-8 bg-[#F8F9FA] rounded-3xl">
            <a href="/">
              <img src={LogoDark} className="mx-auto w-28" alt="" />
            </a>
            <div className="my-6">
              <label htmlFor="phoneNumber" className=" text-sm text-gray-500  ">
                Phone Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                  <p className="text-xs font-medium">+233</p>
                </div>
                <input
                  type="text"
                  className="block w-[16rem] sm:w-[22rem] border-0 p-4 ps-12 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                  placeholder="50xxxxxxx"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{9}$/, // Validates that the phone number consists of exactly 9 digits
                      message: "Phone number must be 9 digits",
                    },
                  })}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button
                  type="submit"
                  onClick={handleSubmit(handleVerifyClick)}
                  className="text-white absolute end-2.5 bottom-2.5 bg-[#1A9AFB] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xs px-4 py-2 "
                >
                  {isLoading ? <Loader /> : "Verify"}
                </button>
              </div>

              <p className="text-red-500 text-xs">
                {errors?.phoneNumber?.message?.toString()}
              </p>
            </div>
            <p className=" text-sm text-center text-gray-400">
              Don&#x27;t have an account yet?{" "}
              <Link
                to="/auth/sign-up"
                className="text-blue-500 focus:outline-none focus:underline hover:underline"
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyUser;
