export const GroupWelfareRequest = {
  groupwelfarefields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },

    {
      label: "Name of Group",
      name: "nameOfGroup",
      type: "text",
      required: true,
    },
    {
      label: "Number of employees or members",
      name: "numberOfEmployees",
      type: "number",
      required: true,
    },
    {
      label: "Desired amounts payable per individual",
      name: "desiredAmounts",
      type: "text",
      required: true,
    },
    {
      label: "Age Range",
      name: "ageRange",
      type: "text",
      required: true,
    },
  ],
};
