import React from "react";
import { useLocation } from "react-router-dom";
import MotorVehicleQuoteSidebar from "./motorVehicleQuote/MotorVehicleQuoteSidebar";
import MoneyInsuranceQuoteSidebar from "./moneyInsuranceQuote/MoneyInsuranceQuoteSidebar";
import QuoteForms from "./QuoteForms";
import { MoneyInsuranceRequest } from "./moneyInsuranceQuote/MoneyInsuranceQuoteData";
import { MotorVehicleRequest } from "./motorVehicleQuote/MotoVehicleQuoteData";
import PlantMachineryQuoteSidebar from "./plantAndMachinery/PlantMachineryQuoteSidebar";
import { PlantMachineryRequest } from "./plantAndMachinery/PlantMachineryQuoteData";
import AssetsAllRisksQuoteSidebar from "./assetsAllRisks/AssetsAllRisksQuoteSidebar";
import { AssetsAllRisksRequest } from "./assetsAllRisks/AssetsAllRisksData";
import CyberInsuranceQuoteSidebar from "./cyberInsurance/CyberInsuranceQuoteSidebar";
import { cyberInsuranceRequest } from "./cyberInsurance/CyberInsuranceQuoteData";
import ContractorRisksQuoteSidebar from "./contractorsRisk/ContractorRisksQuoteSidebar";
import { ContractorRiskRequest } from "./contractorsRisk/ContractorRiskData";
import MarineQuoteSidebar from "./marine/MarineRisksQuoteSidebar";
import { MarineRequest } from "./marine/MarineData";
import ProfessionalIndeminitySidebar from "./professionalIndeminity/ProfessionalIndeminitySidebar";
import { ProfessionalIndeminityRequest } from "./professionalIndeminity/ProfessionalIndeminityData";
import WorkersInsuranceSidebar from "./workersInsurance/WorkersInsuranceSidebar";
import { WorkersInsuranceRequest } from "./workersInsurance/WorkersInsuranceData";
import GroupHealthQuoteSidebar from "./groupHealth/GroupHealthQuoteSidebar";
import { GroupHealthRequest } from "./groupHealth/GroupHealthData";
import GroupLifeSidebar from "./groupLife/GroupLifeSidebar";
import { GroupLifeRequest } from "./groupLife/GroupLifeData";
import GroupTravelSidebar from "./groupTravel/GroupTravelSidebar";
import { GroupTravelRequest } from "./groupTravel/GroupTravelData";
import GroupWelfareSidebar from "./groupWelfare/GroupWelfareSidebar";
import { GroupWelfareRequest } from "./groupWelfare/GroupWelfareData";
import QuoteFormGroup from "./QuoteFormGroup";


const quotes = {
  "/insurance-quote/Motor-Vehicle-&-Fleet": {
    sidebar: <MotorVehicleQuoteSidebar />,
    form: <QuoteForms fields={MotorVehicleRequest.motorvehiclefields} />,
    name: "Motor Vehicle & Fleet",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615431/img_motor-vehicle-fleet_yhsolm.webp",
  },
  "/insurance-quote/Money-Insurance": {
    sidebar: <MoneyInsuranceQuoteSidebar />,
    form: <QuoteForms fields={MoneyInsuranceRequest.moneyInsurancefields} />,
    name: "Money Insurance",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615427/img_money-insurance_lwedf2.webp",
  },
  "/insurance-quote/Plant-&-Machinery": {
    sidebar: <PlantMachineryQuoteSidebar />,
    form: <QuoteForms fields={PlantMachineryRequest.plantmachineryfields} />,
    name: "Plant & Machinery",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615442/img_plant-machinery_aokqrj.webp",
  },
  "/insurance-quote/Assets-All-Risks": {
    sidebar: <AssetsAllRisksQuoteSidebar />,
    form: <QuoteForms fields={AssetsAllRisksRequest.assetsrisksfields} />,
    name: "Assets All Risks",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615375/img_asset-all-risk_e9vvn0.webp",
  },
  "/insurance-quote/Cyber-Insurance": {
    sidebar: <CyberInsuranceQuoteSidebar />,
    form: <QuoteForms fields={cyberInsuranceRequest.cyberInsurancefields} />,
    name: "Cyber Insurance",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615376/img_cyber-insurance_quxefv.webp",
  },
  "/insurance-quote/Contractors-All-Risk": {
    sidebar: <ContractorRisksQuoteSidebar />,
    form: <QuoteForms fields={ContractorRiskRequest.contractorsriskfields} />,
    name: "Contractors All Risk",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615375/img_contractors_risk_apkedh.webp",
  },
  "/insurance-quote/Marine": {
    sidebar: <MarineQuoteSidebar />,
    form: <QuoteForms fields={MarineRequest.marinefields} />,
    name: "Marine",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615424/img_marine-insurance_pe1h1t.webp",
  },
  "/insurance-quote/Professional-Indemnity": {
    sidebar: <ProfessionalIndeminitySidebar />,
    form: (
      <QuoteForms
        fields={ProfessionalIndeminityRequest.professionalindeminityfields}
      />
    ),
    name: "Professional Indemnity",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615442/img_professional-indemnity_x4egfr.webp",
  },
  "/insurance-quote/Workers-Compensation": {
    sidebar: <WorkersInsuranceSidebar />,
    form: (
      <QuoteForms fields={WorkersInsuranceRequest.workersInsurancefields} />
    ),
    name: "Workers Compensation",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615456/img_workers-compensation_zda0ed.webp",
  },
  "/insurance-quote-group/Group-Health": {
    sidebar: <GroupHealthQuoteSidebar />,
    form: <QuoteFormGroup fields={GroupHealthRequest.grouphealthfields} />,
    name: "Group Health",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615377/img_group-health_trlz5n.webp",
  },
  "/insurance-quote-group/Group-Life": {
    sidebar: <GroupLifeSidebar />,
    form: <QuoteFormGroup fields={GroupLifeRequest.grouplifefields} />,
    name: "Group Life",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615381/img_group-life-insurance_mryohk.webp",
  },
  "/insurance-quote-group/Group-Travel": {
    sidebar: <GroupTravelSidebar />,
    form: <QuoteFormGroup fields={GroupTravelRequest.grouptravelfields} />,
    name: "Group Travel",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615380/img_group-travel_gths5q.jpg",
  },
  "/insurance-quote-group/Group-Welfare": {
    sidebar: <GroupWelfareSidebar />,
    form: <QuoteFormGroup fields={GroupWelfareRequest.groupwelfarefields} />,
    name: "Group Welfare",
    image: "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615382/img_group-welfare_f25y3d.webp",
  },
};

const QuotePlan = () => {
  const { pathname } = useLocation();
  const quote = quotes[pathname];

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${quote?.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-[#003862] h-[60vh] w-full flex items-center justify-center bg-opacity-80">
          <div className="px-4 sm:px-6 lg:px-8 py-10 space-y-8">
            <div className="text-center mx-auto">
              <h1 className="block font-bold text-gray-200 text-4xl sm:text-5xl">
                {quote ? quote.name : "This policy would be available soon"}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-6 md:py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-7xl ">
        <div className="grid gap-3 gap-x-8 sm:grid-cols-2 lg:grid-cols-3 items-stretch">
          {quote && (
            <>
              {quote.sidebar}
              {quote.form}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotePlan;
