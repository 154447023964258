import { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../presentation/components/Sidebar";
import ProtectedRoutes from "../presentation/pages/userPortal/auth/utils/ProtectedRoutes";
import Header from "../presentation/components/Header";

const MainLayout = () => {
  const [sideBar, setSideBar] = useState(false);

  return (
    <div className="flex h-screen bg-white">
      <Sidebar sideBar={sideBar} setSideBar={setSideBar} />
      <div className="flex flex-col flex-1 w-full">
        <Header sideBar={sideBar} setSideBar={setSideBar} />
        <main className="h-full overflow-y-auto p-10 md:p-4 lg:p-10">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default ProtectedRoutes(MainLayout);
