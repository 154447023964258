export const MarineRequest = {
  marinefields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Details of goods being transported",
      name: "goodsTransportation",
      type: "textarea",
      as: "textarea",
      required: false,
    },
    {
      label: "Method of transportation",
      name: "vehicle-category",
      type: "select",
      options: [
        { key: "AIR", value: "Air" },
        { key: "SEA", value: "Sea" },
      ],
    },
    {
      label: "Frequency of shipments in a year",
      name: "frequencyShipments",
      type: "text",
      required: true,
    },
    {
      label: "Value of goods per shipment",
      name: "valueOfGoods",
      type: "text",
      required: true,
    },
  ],
};
