import React from "react";
import UpdatesActions from "./components/UpdatesActions";
import GetSocialWithUs from "./components/GetSocialWithUs";
import TipsNews from "./components/TipsNews";
import PolicyStats from "./components/PolicyStats";

const Dashboard = () => {
  return (
    <div>
      <UpdatesActions />
      <PolicyStats />
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-6  mt-16">
        <GetSocialWithUs />
        <TipsNews />
      </div>
    </div>
  );
};

export default Dashboard;
