import React from "react";
import { ArrowRightUp } from "../../../../../assets";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const serviceData = [
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610407/purchase_z0nwih.webp",
      color: "#D1EAFE",
      title: "Insurance Broking",
      body: "We assist our clients, both individuals and businesses to find suitable insurance coverage by comparing options and analyzing their needs to obtain policies tailored to their specific requirements.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610393/discuss_wsn93i.webp",
      color: "#FFC6DB",
      title: "Insurance Audit",
      body: "Through our Risk Assessment strategies, we identify gaps in insurance coverage to optimize the value our clients obtain from existing policies and recommend the necessary areas of cover to prevent or minimize losses.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610392/falling_motdt5.webp",
      color: "#FFB9B9",
      title: "Risk Management",
      body: "We identify, assess, and prioritize risks in order to minimize, monitor, and control their potential impact on organizations.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610392/closeup_rlmh4y.webp",
      color: "#FFEFBE",
      title: "Claims management",
      body: "With our simplified technology-based claims process, we assist clients with claims processing from notification to settlement creating a hustle-free journey through the process",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610396/insurtech_tr01mx.webp",
      color: "#ffb9b9",
      title: "InsurTech Solution",
      body: "We provide innovative technology and digital solutions to insurance companies, corporate businesses and affinity groups, to enhance insurance processes and provide insurance solutions the modern way.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610407/strategy_jqz3rq.webp",
      color: "#D1EAFE",
      title: "Insurance Advisory",
      body: "We provide professional advice to our clients to help them make informed decisions about their insurance needs. This we do through our risk assessment services, insurance audit and strategic advice to reduce or eliminate their financial risks exposures.",
    },
  ];

  const handleSlideClick = (slide) => {
    navigate(`/insurance-service/${slide.title.replace(/\s+/g, "-")}`, {
      state: { slide },
    });
  };
  return (
    <div className="">
      <Swiper
        spaceBetween={10}
        loop={true}
        slidesPerView={3}
        navigation={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        breakpoints={{
          0: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          375: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          575: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          767: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
        }}
        className="mySwiper"
      >
        <div>
          {serviceData.map((slide, index) => (
            <SwiperSlide key={index}>
              <div
                onClick={() => handleSlideClick(slide)}
                style={{ backgroundColor: slide.color }}
                className="max-w-md rounded-3xl group"
              >
                <div className="overflow-hidden relative">
                  <img
                    src={slide.image}
                    className="aspect-video w-full rounded-t-3xl object-cover transition-all duration-300 group-hover:scale-105"
                    alt=""
                  />
                </div>{" "}
                <div className="relative">
                  <div
                    style={{ borderColor: slide.color }}
                    className="absolute -bottom-6 right-10 rounded-full border-4 w-16 h-16 bg-[#003862]"
                  >
                    <img
                      className="transition-all duration-300 group-hover:rotate-45 pl-[15px] w-12 h-12 "
                      src={ArrowRightUp}
                      alt=""
                    />
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-3xl pt-6 pb-2 font-bold text-[#003862]">
                    {slide.title}
                  </h3>
                  <p className="mt-1 text-base font-medium pb-6 text-[#003862]">
                    {slide.body.length > 50
                      ? `${slide.body.substring(0, 50)}...`
                      : slide.body}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default Services;
