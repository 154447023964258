import React from "react";

const UpdatesActionsLoader = () => {
  return (
    <div className="grid gap-5 row-gap-5 sm:grid-cols-2 lg:grid-cols-3 my-4">
      {[1, 2, 3].map((_, index) => (
        <div
          key={index}
          className="max-w-sm bg-[#f9f9f9] rounded-3xl p-6 animate-pulse"
        >
          <div className="flex flex-row">
            <div className="flex items-center justify-center w-24 h-8 mr-2 rounded-full bg-[#E6E5E5]">
              <div className="w-5 h-5 bg-gray-300 rounded-full"></div>
            </div>
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-4 bg-gray-300 rounded w-5/6"></div>
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpdatesActionsLoader;
