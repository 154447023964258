import React from "react";
import { PiPlusCircleBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { MotorPlanIcon } from "../../../../assets";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const MyPolicy = () => {
  return (
    <div>
      <div className="mb-6">
        <h1 className="text-2xl mb-4 font-bold">Active Policies</h1>
        <div className="grid md:grid-cols-2 gap-4 lg:grid-cols-3">
          <div className="max-w-sm group bg-[#F9F9F9] hover:bg-green-100 rounded-3xl p-6">
            <div className="flex flex-wrap items-center mb-4">
              <img
                src={MotorPlanIcon}
                alt=""
                className="w-8 h-8 p-1.5 mr-2 bg-[#d2d1d1] rounded-full"
              />

              <button
                type="button"
                className="inline-flex cursor-pointer items-center justify-center rounded-full  bg-green-500 px-4 py-2 text-sm text-white  "
              >
                Paid
              </button>
            </div>

            <div>
              <h6 className="mb-2 text-md font-bold leading-5">Motor Plan</h6>
              <p className="text-sm mb-6 text-gray-700">
                This policy covers loss or damage to your personal or commercial
                vehicles, damage to property beloging to others persons, as well
                as injuries and death to others.
              </p>

              <button className=" flex items-center underline font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                View More
                <span
                  aria-hidden="true"
                  className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                >
                  <HiOutlineArrowNarrowRight />
                </span>
              </button>
            </div>
          </div>
          <Link to="/buy-insurance" className="group">
            <div className="h-full flex flex-row items-center justify-center text-[#1A9AFB]  gap-x-2 p-10 border-2 border-dashed rounded-3xl cursor-pointer bg-gray-50  relative overflow-hidden">
              <div className="absolute top-0 right-full w-full h-full bg-[#1A9AFB] transform group-hover:translate-x-full group-hover:scale-102 transition duration-300"></div>
              <PiPlusCircleBold className="w-8 h-8 mb-3 group-hover:text-white z-10" />
              <p className="mb-2 text-base font-semibold group-hover:text-white z-10">
                Add Policy
              </p>
            </div>
          </Link>
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-2xl mb-4 font-bold">Pending Renewals</h1>
        <div className="grid md:grid-cols-2 gap-4 lg:grid-cols-3">
          <div className="max-w-sm group bg-[#F9F9F9] hover:bg-yellow-100 rounded-3xl p-6">
            <div className="flex flex-wrap items-center mb-4">
              <img
                src={MotorPlanIcon}
                alt=""
                className="w-8 h-8 p-1.5 mr-2 bg-[#d2d1d1] rounded-full"
              />

              <button
                type="button"
                className="inline-flex cursor-pointer items-center justify-center rounded-full  bg-yellow-500 px-4 py-2 text-sm text-gray-800  "
              >
                Pending
              </button>
            </div>

            <div>
              <h6 className="mb-2 text-md font-bold leading-5">Motor Plan</h6>
              <p className="text-sm mb-6 text-gray-700">
                This policy covers loss or damage to your personal or commercial
                vehicles, damage to property beloging to others persons, as well
                as injuries and death to others.
              </p>

              <button className=" flex items-center underline font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                View More
                <span
                  aria-hidden="true"
                  className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                >
                  <HiOutlineArrowNarrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-2xl mb-4 font-bold">Expired Policies</h1>
        <div className="grid md:grid-cols-2 gap-4 lg:grid-cols-3">
          <div className="max-w-sm group bg-[#F9F9F9] hover:bg-red-100 rounded-3xl p-6">
            <div className="flex flex-wrap items-center mb-4">
              <img
                src={MotorPlanIcon}
                alt=""
                className="w-8 h-8 p-1.5 mr-2 bg-[#d2d1d1] rounded-full"
              />

              <button
                type="button"
                className="inline-flex cursor-pointer items-center justify-center rounded-full  bg-red-600 px-4 py-2 text-sm text-white  "
              >
                Expired
              </button>
            </div>

            <div>
              <h6 className="mb-2 text-md font-bold leading-5">Motor Plan</h6>
              <p className="text-sm mb-6 text-gray-700">
                This policy covers loss or damage to your personal or commercial
                vehicles, damage to property beloging to others persons, as well
                as injuries and death to others.
              </p>

              <button className=" flex items-center underline font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                View More
                <span
                  aria-hidden="true"
                  className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                >
                  <HiOutlineArrowNarrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-2xl mb-4 font-bold">Active Claims</h1>
        <div className="grid md:grid-cols-2 gap-4 lg:grid-cols-3">
          <div className="max-w-sm group bg-[#F9F9F9] hover:bg-blue-100 rounded-3xl p-6">
            <div className="flex flex-wrap items-center mb-4">
              <img
                src={MotorPlanIcon}
                alt=""
                className="w-8 h-8 p-1.5 mr-2 bg-[#d2d1d1] rounded-full"
              />

              <button
                type="button"
                className="inline-flex cursor-pointer items-center justify-center rounded-full  bg-blue-500 px-4 py-2 text-sm text-white  "
              >
                Active
              </button>
            </div>

            <div>
              <h6 className="mb-2 text-md font-bold leading-5">Motor Plan</h6>
              <p className="text-sm mb-6 text-gray-700">
                This policy covers loss or damage to your personal or commercial
                vehicles, damage to property beloging to others persons, as well
                as injuries and death to others.
              </p>

              <button className=" flex items-center underline font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                View More
                <span
                  aria-hidden="true"
                  className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                >
                  <HiOutlineArrowNarrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-2xl mb-4 font-bold">Pending Claims</h1>
        <div className="grid md:grid-cols-2 gap-4 lg:grid-cols-3">
          <div className="max-w-sm group bg-[#F9F9F9] hover:bg-orange-100 rounded-3xl p-6">
            <div className="flex flex-wrap items-center mb-4">
              <img
                src={MotorPlanIcon}
                alt=""
                className="w-8 h-8 p-1.5 mr-2 bg-[#d2d1d1] rounded-full"
              />

              <button
                type="button"
                className="inline-flex cursor-pointer items-center justify-center rounded-full  bg-orange-500 px-4 py-2 text-sm text-white  "
              >
                Pending
              </button>
            </div>

            <div>
              <h6 className="mb-2 text-md font-bold leading-5">Motor Plan</h6>
              <p className="text-sm mb-6 text-gray-700">
                This policy covers loss or damage to your personal or commercial
                vehicles, damage to property beloging to others persons, as well
                as injuries and death to others.
              </p>

              <button className=" flex items-center underline font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                View More
                <span
                  aria-hidden="true"
                  className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                >
                  <HiOutlineArrowNarrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPolicy;
