import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

const Header = ({ sideBar, setSideBar }) => {
  const [fullName, setFullName] = useState(
    sessionStorage.getItem("userFullName")
  );
  return (
    <header className="z-10 py-8  bg-white ">
      <div className="flex items-center justify-between h-8 px-10 mx-auto">
        <button
          className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none"
          onClick={() => setSideBar(!sideBar)}
          aria-label="Menu"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm1 4a1 1 0 100 2h12a1 1 0 100-2H4z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className=" justify-left hidden md:flex flex-1 lg:mr-32">
          {" "}
          <h1 className="text-3xl">Hello, {fullName}</h1>
        </div>
        <ul className="flex items-center flex-shrink-0 space-x-6">
          <li className="relative">
            <a
              href="https://wa.me/233559689972"
              target="_blank"
              rel="noreferrer"
              className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-[#1A9AFB] transition duration-300 ease-out border-2 border-[#1A9AFB] rounded-2xl shadow-md group"
            >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#1A9AFB] group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-[#1A9AFB] transition-all duration-300 transform group-hover:translate-x-full ease">
                <FaWhatsapp className="text-xl " />
                Let's Chat
              </span>
              <span className="relative invisible">Buy new Policy</span>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
