import React from "react";
import ClaimCard from "./components/ClaimCard";

const Claims = () => {
  return (
    <div>
      <ClaimCard />
    </div>
  );
};

export default Claims;
