import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/app-constants";
import { BaseResponse } from "../models/response/base-response";
import {
  CreateUserRequest,
  createPasswordRequest,
  signInRequest,
  verifyOtpRequest,
} from "../models/request/auth-request";
import {
  CustomerResponse,
  createPasswordResponse,
} from "../models/response/auth-response";

export const authService = createApi({
  reducerPath: "authService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),
  tagTypes: ["Auth"],
  endpoints: (build) => ({
    verifyUserByMsisdn: build.mutation<BaseResponse<any[]>, string>({
      query: (msisdn) => ({
        url: `customers/msisdn/${msisdn}`,
        method: "GET",
      }),
      invalidatesTags: ["Auth"],
    }),
    signIn: build.mutation<BaseResponse<any>, signInRequest>({
      query: (body: signInRequest) => ({
        url: "auth/signin",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    createUser: build.mutation<BaseResponse<any[]>, CreateUserRequest>({
      query: (body: CreateUserRequest) => ({
        url: "auth/signup",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    verifyOtp: build.mutation<BaseResponse<any[]>, verifyOtpRequest>({
      query: (body: verifyOtpRequest) => ({
        url: "otp/verify",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    createPassword: build.mutation<
      BaseResponse<createPasswordResponse>,
      createPasswordRequest
    >({
      query: (body: createPasswordRequest) => ({
        url: "auth/create-password",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const {
  useVerifyUserByMsisdnMutation,
  useSignInMutation,
  useCreateUserMutation,
  useVerifyOtpMutation,
  useCreatePasswordMutation,
} = authService;
