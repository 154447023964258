import React from "react";
import LoginCard from "./components/LoginCard";
import NavHeader from "../../../../components/NavHeader";
import Footer from "../../../../components/Footer";

const Login = () => {
  return (
    <div>
      <NavHeader />
      <LoginCard />
      <Footer />
    </div>
  );
};

export default Login;
