import { ArrowRight } from "../../../../assets";

const QuoteForms = ({ fields }) => {
  return (
    <div className="lg:col-span-2 my-auto">
      <form>
        <div className="grid gap-5 lg:grid-cols-2 my-4">
          {fields.map((field, index) => (
            <div className="space-y-2" key={index}>
              <label
                htmlFor={field.name}
                className="inline-block text-sm font-medium text-[#003862] mt-2.5"
              >
                {field.label}
              </label>
              {field.type === "text" && (
                <input
                  id={field.name}
                  type="text"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 bg-gray-50 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "email" && (
                <input
                  id={field.name}
                  type="email"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 bg-gray-50 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "date" && (
                <input
                  id={field.name}
                  type="date"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm rounded-lg bg-gray-50 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "number" && (
                <input
                  id={field.name}
                  type="number"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
                {field.type === "file" && (
                <input
                  id={field.name}
                  type="file"
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
              {field.type === "select" && field.options && (
                <select
                  id={field.name}
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <option selected disabled value="">
                    Select an option
                  </option>
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </select>
              )}
              {field.type === "textarea" && (
                <textarea
                  id={field.name}
                  name={field.name}
                  required={field.required}
                  className="py-4 px-3 block w-full border-2 border-gray-200 shadow-sm bg-gray-50 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              )}
            </div>
          ))}
        </div>
        <button
          type="submit"
          className=" flex underline group justify-end w-full font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200"
        >
          Submit
          <span
            aria-hidden="true"
            className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
          >
            <img src={ArrowRight} alt="" />
          </span>
        </button>
      </form>
    </div>
  );
};

export default QuoteForms;
