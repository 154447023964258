import React from "react";
import { CiPower } from "react-icons/ci";
import { GoDotFill, GoQuestion } from "react-icons/go";
import { Imperial } from "../../../../../assets";

const PlannersProtectionSidebar = () => {
  const policyInfo = [
    {
      content:
        "Legal liability for compensation to employees for injury or death",
    },
    {
      content: "Loss or damage to buildings or stock",
    },
    {
      content: "Loss or damage to clothes while being transported",
    },
    {
      content: "Loss or damage of décor items at the event site",
    },
  ];

  const underwritten = [
    {
      logo: Imperial,
    },
  ];
  return (
    <div className="bg-[#003862] lg:col-span-1 rounded-3xl">
      <div className="text-white p-8 my-4 ">
        <p className="text-base mb-6">
          This Policy protects event planners and decorators against loss or
          damage to décor items as a result of accident, fire, theft, flood or
          other perils as well as injury or death to employees.
        </p>
        <div className="w-full max-w-lg divide-y divide-layer-2 divide-opacity-20 divide-[#003862]">
          <h5 className="mb-6 text-2xl font-bold leading-none ">
            Policy Information
          </h5>
          {policyInfo.map((data, index) => (
            <div key={index} className="block py-2 ">
              <div className="flex items-center justify-between space-x-4">
                <GoDotFill className=" text-heading text-[#1A9AFB]" />
                <h4 className="flex-1 text-sm  text-heading ">
                  {data.content}
                </h4>
              </div>
            </div>
          ))}
          <div className="pt-5">
            <p className="text-white text-xs pt-4 text-center underline">
              Terms and conditions may apply
            </p>
            <p className="text-white text-xs text-center pt-4 underline">
              Underwritten by
            </p>
            <div className="grid items-center mt-2  gap-2">
              {underwritten.map((data, index) => (
                <img
                  key={index}
                  className="mx-auto w-28 bg-white rounded-full p-2"
                  src={data.logo}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlannersProtectionSidebar;
