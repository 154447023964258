import React from "react";
import MissionVision from "./components/MissionVision";
import CoreValues from "./components/CoreValues";
import Journey from "./components/Journey";
import OurPeople from "./components/OurPeople";

const About = () => {
  return (
    <div>
      <div>
        <div
          style={{
            backgroundImage: `url("https://res.cloudinary.com/dzitjtuoj/image/upload/v1720609758/carousel1_fp0bk6.webp")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="bg-[#003862] h-[60vh] w-full flex items-center justify-center bg-opacity-80">
            <div className=" px-4 sm:px-6 lg:px-8 py-8 space-y-8">
              <div className="max-w-5xl text-center mx-auto">
                <h1
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  className="block font-bold text-gray-200 text-4xl sm:text-5xl md:text-6xl "
                >
                  Our story
                </h1>
              </div>
            </div>
          </div>
        </div>
        <MissionVision />
        <CoreValues />
        <Journey />
        <OurPeople />
      </div>
    </div>
  );
};

export default About;
