import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import Products from "./Products";
import Services from "./Services";

const tabs = [
  {
    title: "Products",
    content: <Products />,
  },
  {
    title: "Services",
    content: <Services />,
  },
];

const OurSolutions = () => {
  return (
    <div className="bg-[#F3F2F2]">
      <div className="max-w-[90rem] mx-auto px-2 py-10 ">
        <h1
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="true"
          className="text-3xl px-6 md:text-4xl font-bold mt-6 mb-12 text-[#003862] "
        >
          Our solutions
        </h1>
        <Tab.Group>
          <Tab.List className="space-x-2">
            {tabs.map((tab) => (
              <Tab key={tab.title} as={Fragment}>
                {({ selected }) => (
                  <button
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-once="true"
                    className={`${
                      selected
                        ? "bg-[#1A9AFB] text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent"
                        : "border-transparent bg-transparent text-text hover:bg-heading/5 hover:text-heading"
                    } inline-flex cursor-pointer items-center justify-center rounded-full border-2 px-6 py-3 text-base font-semibold focus:outline-none disabled:opacity-30`}
                  >
                    {tab.title}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-4">
            {tabs.map((tab) => (
              <Tab.Panel key={tab.title}>
                <div
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  className="text-sm text-text"
                >
                  {tab.content}
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
export default OurSolutions;
