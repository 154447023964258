import React from "react";
import CustomerActivityData from "./components/CustomerActivityData";

const CustomerActivity = () => {
  return (
    <div>
      <div className="container mx-auto p-4">
        <div className="text-2xl font-bold mb-4 ">
          <h1>Activities</h1>
        </div>
        <CustomerActivityData />
      </div>
    </div>
  );
};

export default CustomerActivity;
