import React from "react";
import { GoDotFill } from "react-icons/go";
import { SanlamAllianz } from "../../../../../assets";

const HomeOwnerSidebar = () => {
  const policyInfo = [
    {
      content: "Protects your residential building",
    },
    {
      content: "Covers the valuable content of your home",
    },
    {
      content:
        "With this plan, it can be extended to buy Personal accident cover for your family",
    },
  ];

  const underwritten = [
    {
      logo: SanlamAllianz,
    },
   
  ];
  return (
    <div className="bg-[#003862] max-w-md rounded-3xl lg:col-span-1">
      <div className="text-white p-8 my-4 ">
        {/* <h5 className="mb-6 flex items-center font-bold leading-none ">
          <GoDotFill className=" text-heading text-[#C7D3DD]" />
          <span className="text-2xl pl-4"> HomeOwners-Plan</span>
        </h5> */}
        <p className="text-base mb-6">
          This policy is designed to cover your homes, residential buildings and
          contents against loss or damage resulting from fire, burglary, flood,
          hurricans, earthquakes and other perils.
        </p>
        <div className="w-full max-w-lg divide-y divide-layer-2 divide-opacity-20 divide-[#003862]">
          <h5 className="mb-6 text-2xl font-bold leading-none ">
            Policy Information
          </h5>
          {policyInfo.map((data, index) => (
            <div key={index} className="block py-2 ">
              <div className="flex items-center justify-between space-x-4">
                <GoDotFill className=" text-heading text-[#1A9AFB]" />
                <h4 className="flex-1 text-sm  text-heading ">
                  {data.content}
                </h4>
              </div>
            </div>
          ))}
       <div className="pt-5">
            <p className="text-white text-xs pt-4 text-center underline">
              Terms and conditions may apply
            </p>
            <p className="text-white text-xs text-center pt-4 underline">
              Underwritten by
            </p>
            <div className="grid items-center mt-2 gap-2">
              {underwritten.map((data, index) => (
                <img
                  key={index}
                  className="mx-auto w-36  bg-white rounded-full p-2"
                  src={data.logo}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOwnerSidebar;
