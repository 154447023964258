import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetActivityByCustomerIdQuery } from "../../../../../../services/portal-service";
import CustomerActivityTable from "./CustomerActivityTable";
import UpdatesActionsPagination from "../../Dashboard/components/UpdatesActionsPagination";
import moment from "moment";

const CustomerActivityData = () => {
  const location = useLocation();
  const [storedUserId, setStoredUserId] = useState(
    sessionStorage.getItem("userId")
  );
  const userId = storedUserId ? parseInt(storedUserId) : 0;
  const { data: response, isLoading } = useGetActivityByCustomerIdQuery(userId);

  const ActivityDetails = response?.data || [];

  const columns = React.useMemo(
    () => [
      {
        Header: "Product",
        accessor: "quote.product.name",
      },
      {
        Header: "Amount (GhS)",
        accessor: "quote.total",
      },
      {
        Header: "Status",
        accessor: (row) =>
          row.quote.isDraft ? (
            <span className="inline-block py-1 px-3 rounded-full bg-blue-500 text-white">
              Pending
            </span>
          ) : (
            <span className="inline-block py-1 px-4 bg-green-500 text-white rounded-full">
              Paid
            </span>
          ),
      },
      {
        Header: "Date",
        accessor: (row) => moment(row.createdDate).format("MMMM Do YYYY"),
      },
      {
        Header: "Activity",
        accessor: "activity",
      },
    ],
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  // Calculate total pages
  const totalPages = Math.ceil(ActivityDetails.length / pageSize);

  // Get current quotes to display
  const currentActivities = ActivityDetails.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <CustomerActivityTable columns={columns} data={currentActivities} />

      <UpdatesActionsPagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default CustomerActivityData;
