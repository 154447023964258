import React from "react";
import { FuelTrade, Novotech, VanUden } from "../../../../assets";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonials = () => {
  const testimonialsData = [
    {
      image: `${VanUden}`,
      name: "VanUden Africa",
      content:
        "We've found a trusted partner in Banbo. Their insurance solutions have been crucial to our company's success. Their professionalism, accessibility, and commitment to our unique requirements have made them an essential asset. We confidently recommend their services to all in the industry.",
    },
    {
      image: `${Novotech}`,
      name: "Novotec Ghana",
      content:
        "Banbo have consistently delivered excellent service to us. Their dedication to our insurance needs has been unwavering. Their expertise and promptness have given us peace of mind, knowing that our business is well-protected.",
    },
    {
      image: `${FuelTrade}`,
      name: "Fuel Trade Limited",
      content:
        "Our company relies on Banbo for insurance expertise. Their quick response time and tailored solutions have saved us time and money. Their service is invaluable, and we trust them to keep our business protected.",
    },
  ];
  return (
    <div>
      <section className="py-12 bg-[#F3F2F2] ">
        <div className="max-w-[90rem] mx-auto">
          <h1
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
            className="text-3xl px-6 md:text-4xl font-bold pb-12 text-[#003862] "
          >
            See what our clients have to say about us.
          </h1>
          <Swiper
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-once="true"
            spaceBetween={20}
            loop={true}
            slidesPerView={3}
            navigation={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination, Autoplay]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              375: {
                slidesPerView: 1,
              },
              575: {
                slidesPerView: 1,
              },
              767: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            className="mySwiper"
          >
            {testimonialsData.map((data, index) => (
              <SwiperSlide key={index}>
                <div className=" bg-white flex flex-col justify-center h-[25rem] lg:h-[20rem] my-auto rounded-3xl ">
                  <h1 className="px-6 text-5xl">"</h1>
                  <p className="px-6 py-2 text-base font-medium text-[#003862]">
                    {data.content}
                  </p>
                  <div className="flex items-center pt-5 px-6">
                    <div className="w-12 h-12 p-3 rounded-full bg-[#F9F9F9]">
                      <img src={data.image} alt="" className="w-8  " />
                    </div>
                    <div>
                      <p className="leading-tight ps-2">{data.name}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
