import React, { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CreateUserRequest } from "../../../../models/request/auth-request";
import { useCreateUserMutation } from "../../../../services/auth-service";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import Loader from "../../../components/Loader";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";

const SignUp = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [certificateType, setCertificateType] = useState("");

  const [formData, setFormData] = useState<CreateUserRequest>({
    name: "",
    dateOfBirth: "",
    email: "",
    ghanaCardNumber: "",
    certificateOfIncorporationNumber: "",
    customerType: "",
    msisdn: "",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [userSignUp, { isLoading }] = useCreateUserMutation();

  const handleFormSubmit = async (e) => {
    // e.preventDefault();
    try {
      // Prepend "GHA-" to the Ghana Card number
      let ghanaCardNumber = formData.ghanaCardNumber;
      if (certificateType === "INDIVIDUAL" && ghanaCardNumber) {
        ghanaCardNumber = "GHA-" + ghanaCardNumber;
      }

      // Prepend "+233" to the phone number
      const phoneNumber = "233" + formData.msisdn;
      const updatedFormData = {
        ...formData,
        msisdn: phoneNumber,
        ghanaCardNumber: ghanaCardNumber,
      };

      const response = await userSignUp(updatedFormData);
      const { code, message, data } = response.error ? response.error["data"] : response["data"]
      if (code === "00") {
        toast.success(message);
        const { id } = data.otp;
        navigate("/auth/verify-otp", {
          state: { id },
        });
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };
  return (
    <div>
      <NavHeader />
      <div className="pt-32 pb-10 flex justify-center items-center">
        <div className="block w-full max-w-md p-8 bg-[#F8F9FA] rounded-3xl">
          {/* <a href="/">
            <img src={LogoDark} className="mx-auto w-28" alt="" />
          </a> */}
          <p className="text-3xl text-[#125182] text-center font-extrabold">
            Sign Up
          </p>
          <div className="my-6">
            <div className="mb-2">
              <label htmlFor="phoneNumber" className=" text-xs text-gray-500  ">
                Full Name
              </label>
              <input
                type="text"
                className="block w-full border-0 p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                placeholder="Full Name"
                {...register("name", {
                  required: "Full name is required", // Updated the required rule
                  pattern: {
                    value: /^[A-Za-z0-9\- ]+$/,
                    message: "Please enter only alphanumeric",
                  },
                })}
                onChange={handleInputChange}
              />
              <p className="text-red-500 text-xs">
                {errors?.name?.message?.toString()}
              </p>
            </div>
            <div className="mb-2">
              <label htmlFor="phoneNumber" className=" text-xs text-gray-500  ">
                Phone Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                  <p className="text-xs font-medium">+233</p>
                </div>
                <input
                  type="text"
                  className="block w-full border-0 p-4 ps-12 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                  placeholder="50xxxxxxx"
                  {...register("msisdn", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{9}$/, // Validates that the phone number consists of exactly 9 digits
                      message: "Phone number must be 9 digits",
                    },
                  })}
                  onChange={handleInputChange}
                />
                {errors.msisdn && (
                  <p className="text-red-500 text-xs">
                    {errors.msisdn.message?.toString()}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="phoneNumber" className=" text-xs text-gray-500  ">
                Date of Birth
              </label>
              <input
                type="date"
                className="block w-full border-0 p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                {...register("dateOfBirth", {
                  required: "Date of Birth is required", // Added required rule
                })}
                onChange={handleInputChange}
              />
              <p className="text-red-500 text-xs">
                {errors?.dateOfBirth?.message?.toString()}
              </p>
            </div>
            <div className="mb-2">
              <label htmlFor="email" className=" text-xs text-gray-500  ">
                Email
              </label>
              <input
                type="email"
                className="block w-full border-0 p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
                placeholder="someone@example.com"
                {...register("email", {
                  required: { value: true, message: "Email is required" },

                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Email format is invalid",
                  },
                })}
                onChange={handleInputChange}
              />
              <p className="text-red-500 text-xs">
                {errors?.email?.message?.toString()}
              </p>
            </div>
            <div className="mb-2">
              <label htmlFor="email" className=" text-xs text-gray-500  ">
                Certificate Type
              </label>
              <select
                value={certificateType}
                {...register("customerType", {
                  required: "customerType is required", // Added required rule
                })}
                onChange={(e) => {
                  setCertificateType(e.target.value);
                  handleInputChange(e); // Call handleInputChange when the select value changes
                }}
                className="block w-full border-0 p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9] "
              >
                <option value="" selected>
                  Select certificate
                </option>
                <option value="INDIVIDUAL">Individual</option>
                <option value="COMPANY">Company</option>
              </select>
            </div>
            {certificateType === "INDIVIDUAL" && (
              <div className="mb-3">
                <label
                  htmlFor="ghanaCardNumber"
                  className="text-xs text-gray-500"
                >
                  Ghana Card Number
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                    <p className="text-xs font-medium">GHA</p>
                  </div>
                  <input
                    type="text"
                    id="ghanaCardNumber"
                    className="block w-full border-0 p-4 ps-12 text-sm text-gray-900 rounded-lg bg-[#E9E9E9]"
                    placeholder="xxxxxxxxx-x"
                    {...register("ghanaCardNumber", {
                      pattern: {
                        value: /^[0-9]{9}-[0-9]$/, // Validates the Ghana Card number format (e.g., 123456789-0)
                        message:
                          "Ghana Card number must have the format 123456789-0",
                      },
                    })}
                    onChange={handleInputChange}
                  />

                  <p className="text-red-500 text-xs">
                    {errors?.ghanaCardNumber?.message?.toString()}
                  </p>
                </div>
              </div>
            )}
            {certificateType === "COMPANY" && (
              <div className="mb-3">
                <label
                  htmlFor="certificateOfIncorporation"
                  className="text-xs text-gray-500"
                >
                  Certificate Of Incorporation Number
                </label>
                <input
                  type="text"
                  id="certificateOfIncorporation"
                  name="certificateOfIncorporationNumber"
                  onChange={handleInputChange}
                  className="block w-full border-0 p-4 text-sm text-gray-900 rounded-lg bg-[#E9E9E9]"
                  placeholder="Certificate Of Incorporation Number"
                />
              </div>
            )}
            <button
              disabled={
                !formData.name ||
                !formData.email ||
                !formData.dateOfBirth ||
                !formData.customerType ||
                !formData.msisdn
              }
              onClick={handleSubmit(handleFormSubmit)}
              className="w-full px-4 py-3 tracking-wide rounded-lg text-white transition-colors duration-200 transform bg-[#125182] hover:bg-[#215CA4]  focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              {isLoading ? (
                <p className="mx-auto text-center">
                  <Loader />
                </p>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
          <p className=" text-sm text-center text-gray-400">
            Have an account?{" "}
            <Link
              to="/auth/login"
              className="text-blue-500 focus:outline-none focus:underline hover:underline"
            >
              Sign In
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
