export const cyberInsuranceRequest = {
  cyberInsurancefields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Nature of Business",
      name: "natureOfBusiness",
      type: "text",
      required: true,
    },

    {
      label: "Type of data",
      name: "typeOfData",
      type: "select",
      options: [
        { key: "PERSONAL", value: "Personal" },
        { key: "FINANCIAL", value: "Financial" },
        { key: "INTELLECTUAL_PROPERTY", value: "Intellectual Property" },
      ],
    },
    {
      label: "History of cyber incidents",
      name: "History",
      type: "textarea",
      as: "textarea",
      required: false,
    },
  ],
};
