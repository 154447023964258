import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../presentation/pages/landingPage/homepage/HomePage";
import LandingPageLayout from "../shared/LandingPageLayout";
import About from "../presentation/pages/landingPage/about/About";
import BuyInsurance from "../presentation/pages/landingPage/insurance/buyInsurance/BuyInsurance";
import IndividualFamily from "../presentation/pages/landingPage/insurance/buyInsurance/components,tsx/products/IndividualFamily";
import GroupProtection from "../presentation/pages/landingPage/insurance/buyInsurance/components,tsx/products/GroupProtection";
import BusinessProtection from "../presentation/pages/landingPage/insurance/buyInsurance/components,tsx/products/BusinessProtection";
import SpecialProtection from "../presentation/pages/landingPage/insurance/buyInsurance/components,tsx/products/SpecialProtection";
import PolicyPlan from "../presentation/pages/landingPage/insurance/PolicyItems/PolicyPlan";
import SignUp from "../presentation/pages/userPortal/auth/SignUp";
import MainLayout from "../shared/MainLayout";
import Dashboard from "../presentation/pages/userPortal/main/Dashboard/Dashboard";
import VerifyOtp from "../presentation/pages/userPortal/auth/VerifyOtp";
import CreatePassword from "../presentation/pages/userPortal/auth/CreatePassword";
import CustomerActivity from "../presentation/pages/userPortal/main/customerActivity/CustomerActivity";
import MyPolicy from "../presentation/pages/userPortal/main/myPolicy/MyPolicy";
import PageNotFound from "../presentation/pages/userPortal/main/PageNotFound";
import Claims from "../presentation/pages/landingPage/claim/Claims";
import Login from "../presentation/pages/userPortal/auth/login/Login";
import PrivacyPolicy from "../presentation/pages/landingPage/PrivacyPolicy";
import InsuranceService from "../presentation/pages/landingPage/insurance/insuranceService/InsuranceService";
import QuotePlan from "../presentation/pages/landingPage/insurance/quoteItems/QuotePlan";
import VerifyUser from "../presentation/pages/userPortal/auth/VerifyUser";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPageLayout />}>
        <Route index element={<Navigate replace to="home" />} />
        <Route path="home" element={<HomePage />} />
        <Route path="about" element={<About />} />
        <Route path="insurance-policy/:title" element={<PolicyPlan />} />
        <Route path="insurance-quote/:title" element={<QuotePlan />} />
        <Route path="insurance-quote-group/:title" element={<QuotePlan />} />
        <Route path="claims" element={<Claims />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="insurance-service/:title" element={<InsuranceService />} />
      </Route>

      <Route path="/buy-insurance" element={<LandingPageLayout />}>
        <Route index element={<Navigate replace to="individual-family" />} />
        <Route element={<BuyInsurance />}>
          <Route path="individual-family" element={<IndividualFamily />} />
          <Route path="group-protection" element={<GroupProtection />} />
          <Route path="business-protection" element={<BusinessProtection />} />
          <Route path="special-protection" element={<SpecialProtection />} />
        </Route>
      </Route>

      <Route path="/auth">
        <Route index element={<Navigate replace to="login" />} />
        <Route path="login" element={<Login />} />
        <Route path="create-password" element={<CreatePassword />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="verify-otp" element={<VerifyOtp />} />
        <Route path="verify-user" element={<VerifyUser />} />
      </Route>

      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="activities" element={<CustomerActivity />} />
        <Route path="my-policies" element={<MyPolicy />} />
      </Route>

      {/* Page not found route */}
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRouter;
