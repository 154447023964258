import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import {
  AirtelTigo,
  ArrowRight,
  GroupHealthPlanIcon,
  Mastercard,
  MotorPlanIcon,
  Mtn,
  Visa,
  Vodafone,
} from "../../../../../assets";

const GroupHealthTestModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <button
        type="submit"
        className="flex underline group justify-end w-full font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200"
        onClick={() => setIsOpen(true)} // Open modal on click
      >
        Next
        <span
          aria-hidden="true"
          className="translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
        >
          <img src={ArrowRight} alt="" />
        </span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-50 items-end justify-center overflow-hidden px-4 pt-4  text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-flex w-full transform flex-col rounded-3xl bg-white text-left align-bottom  transition-all sm:my-8 sm:max-w-3xl sm:align-middle">
                <div className="absolute top-4 right-5">
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex aspect-square cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text child-svg:h-5 child-svg:w-5"
                  >
                    <span className="sr-only">Close</span>
                    <IoCloseCircle className="h-5 w-5" />
                  </button>
                </div>

                <div className="flex-1 px-6 py-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg flex items-center gap-2 font-bold"
                  >
                    <img
                      className="bg-[#E6E5E5] w-9 p-1.5 rounded-full"
                      src={GroupHealthPlanIcon}
                      alt=""
                    />{" "}
                    Kofi Bentil's Group Keep Fit Club Health Plan - Summary
                  </Dialog.Title>
                </div>

                <hr className="border-hr" />

                <div className="max-h-[60vh] overflow-y-auto px-6 py-5 sm:py-6">
                  <div className="grid md:grid-cols-2 mx-auto gap-10">
                    <div>
                      <h1 className="bg-[#E6E5E5] p-1 mb-2 text-sm font-bold ps-3 rounded-full">
                        Group details
                      </h1>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Full name</h1>
                        <p className="text-sm font-bold">Kofi Bentil</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Phone number</h1>
                        <p className="text-sm font-bold">233244456879</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Name of company</h1>
                        <p className="text-sm font-bold">
                          Kofi Bentil Keep fit Club
                        </p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Company Address</h1>
                        <p className="text-sm font-bold">Lapaz Accra</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">
                          Nature of business
                        </h1>
                        <p className="text-sm font-bold">Consulting</p>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <h1 className="bg-[#E6E5E5] p-1 ps-3 mb-2 text-sm  font-bold rounded-full">
                        Policy details
                      </h1>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">
                          Number of employees/members
                        </h1>
                        <p className="text-sm font-bold">20</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">CoverType</h1>
                        <p className="text-sm font-bold">Health</p>
                      </div>
                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Age Range</h1>
                        <p className="text-sm font-bold">25-50 years</p>
                      </div>
                    </div>
                    <div>
                      <h1 className="bg-[#E6E5E5] p-1 mb-2 text-sm font-bold ps-3 rounded-full">
                        Quote details
                      </h1>

                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Created at</h1>
                        <p className="text-sm font-bold">2024-07-08T14:23:29</p>
                      </div>

                      <div className="flex items-center mb-1 px-3 gap-2">
                        <h1 className="text-sm font-normal">Product Name</h1>
                        <p className="text-sm font-bold">Health Plan</p>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="border-hr" />
                <div className="px-6 pt-4">
                  {" "}
                  <div className="flex h-16 flex-shrink-0 items-center justify-end space-x-2">
                    <button className=" flex underline group font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                      Submit
                      <span
                        aria-hidden="true"
                        className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                      >
                        <img src={ArrowRight} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default GroupHealthTestModal;
